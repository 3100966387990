export const us ='Lanin Car Wash fue fundado con el objetivo de ofrecer un servicio de lavado de vehículos que combine calidad, responsabilidad ambiental y un enfoque en la satisfacción del usuario. Desde sus inicios, se ha adaptado a las necesidades de sus clientes, utilizando técnicas y productos innovadores, con un equipo capacitado y apasionado por el cuidado automotriz.'

export const mission = 'Nuestra misión es proporcionar un servicio de lavado de vehículos de alta calidad que combine eficiencia, atención personalizada y prácticas sostenibles. Utilizando productos ecológicos y técnicas innovadoras que minimicen el impacto ambiental. Queremos asegurar la satisfacción de nuestros clientes y contribuir a un entorno más limpio y saludable.'

export const vision = 'Ser referentes en la región de Neuquén en servicios de lavado y venta de productos de para el cuidado de vehículos, comprometidos con la sostenibilidad y la excelencia. Aspiramos a crear conciencia sobre la importancia del cuidado ambiental en la comunidad, liderando con el ejemplo y fomentando prácticas responsables que promuevan un futuro más sostenible para todos. Queremos que cada cliente que confíe en nosotros.'

export const env_policy = ' En Lanin Car Wash, nos comprometemos a cuidar el medio ambiente mediante prácticas sostenibles. Utilizamos productos de limpieza ecológicos, biodegradables y no tóxicos para minimizar nuestro impacto ambiental. Implementamos sistemas y tecnologías que permiten un uso responsable del agua, logrando una reducción significativa en su consumo. Además, gestionamos adecuadamente los desechos generados durante el lavado, promoviendo el reciclaje siempre que sea posible.'

export const compromise = 'Nuestro compromiso con el medio ambiente se fundamenta en prácticas sostenibles que buscan reducir nuestro impacto ecológico y promover un entorno más saludable. Valoramos el aprendizaje constante, manteniéndonos actualizados sobre nuevas tecnologías y métodos para mejorar nuestras prácticas de sostenibilidad. Además, adoptamos la mejora continua como un pilar fundamental, evaluando regularmente nuestros procesos para optimizar operaciones, reducir residuos y aumentar el reciclaje. Así, no solo beneficiamos al medio ambiente, sino que también ofrecemos un servicio de mayor calidad a nuestros clientes, contribuyendo a un futuro más sostenible.'

export const brands = [
    "3-STAR",
    "ACURA",
    "AGRALE",
    "AIXAM",
    "ALFA ROMEO",
    "APPIA",
    "APRILIA",
    "ARCTIC CAT",
    "ARO",
    "ASIA",
    "AUDI",
    "BAIC",
    "BAJAJ",
    "BELAVTOMAZ",
    "BENELLI",
    "BETA",
    "BETAMOTOR",
    "BLAC",
    "BLACKSTONE",
    "BMW",
    "BRAVA",
    "CADILLAC",
    "CAN-AM",
    "CERRO",
    "CF MOTO",
    "CFMOTO",
    "CHANGAN",
    "CHERY",
    "CHEVROLET",
    "CHRYSLER",
    "CITROEN",
    "CORADIR",
    "CORVEN",
    "DA DALT",
    "DACIA",
    "DAELIM",
    "DAEWOO",
    "DAIHATSU",
    "DAIMLER BENZ",
    "DAYAMA",
    "DEUTZ",
    "DEUTZ AGRALE",
    "DEUTZ-AGRALE",
    "DFM",
    "DFSK",
    "DIMEX",
    "DINA",
    "DODGE",
    "DONGFENG",
    "DS",
    "DUCATI",
    "ELITE",
    "ELPRA ELECTRIC",
    "EUROMOT",
    "FAW",
    "FERESA",
    "FERRARI",
    "FIAT",
    "FIAT IVECO",
    "FORD",
    "FOTON",
    "GAC GONOW",
    "GAF",
    "GALLOPER",
    "GAMMA",
    "GARELLI SAHEL",
    "GAZ",
    "GEELY",
    "GEO",
    "GHIGGERI",
    "GILERA",
    "GMC",
    "GREAT WALL",
    "GUERRERO",
    "HAOJUE",
    "HARLEY DAVIDSON",
    "HAVAL",
    "HEI BAO",
    "HERO",
    "HINO",
    "HONDA",
    "HUMMER",
    "HUSQVARNA",
    "HYUNDAI",
    "IMSA",
    "INDIAN",
    "INFINITI",
    "INNOCENTI",
    "INTERNACIONAL",
    "INTERNATIONAL",
    "ISUZU",
    "IVECO",
    "JAC",
    "JAGUAR",
    "JAWA",
    "JEEP",
    "JETOUR",
    "JIANSHE",
    "JINCHENG",
    "JMC",
    "JMSTAR",
    "KAWASAKI",
    "KEEWAY",
    "KELLER",
    "KIA",
    "KIDEN",
    "KIKAI",
    "KONISA",
    "KTM",
    "KYC",
    "KYMCO",
    "LADA",
    "LANCER",
    "LAND ROVER",
    "LEGNANO",
    "LEXUS",
    "LIFAN",
    "LOTUS",
    "MACK",
    "MARUTI",
    "MARUTI SUZUKI",
    "MASERATI",
    "MAVERICK",
    "MAXUS",
    "MAZDA",
    "MCLAREN",
    "MERCEDES BENZ",
    "MERCEDES",
    "METRO",
    "MG",
    "MINI",
    "MITSUBISHI",
    "MONDIAL",
    "MOTO GUZZI",
    "MOTOMEL",
    "MV AGUSTA",
    "NAKAI",
    "NISSAN",
    "NISSAN DIESEL",
    "NORTON",
    "NUUV",
    "OKINOI",
    "OLMO",
    "OPEL",
    "PANTHER",
    "PANTHER QUADS",
    "PEUGEOT",
    "PIAGGIO",
    "PLYMOUTH",
    "POLARIS",
    "PONTIAC",
    "PORSCHE",
    "PROTON",
    "QINGQI",
    "RAM",
    "RANGE ROVER",
    "RENAULT",
    "ROVER",
    "ROYAL ENFIELD",
    "RVM",
    "SAAB",
    "SAAB SCANIA",
    "SANTANA",
    "SANXING",
    "SATURN",
    "SCANIA",
    "SCANIA/MARCOPOL",
    "SEAT",
    "SERO",
    "SHERCO",
    "SHINERAY",
    "SIAM",
    "SKODA",
    "SMART",
    "SOUEAST",
    "SPACE",
    "SSANGYONG",
    "SUBARU",
    "SUMO",
    "SUNEQUIP",
    "SUNRA",
    "SUZUKI",
    "SUZUKI - SANTANA",
    "SYM",
    "TATA",
    "TIBO",
    "TOYOTA",
    "TRIUMPH",
    "TVS",
    "VOGE",
    "VOLKSWAGEN",
    "VOLVO",
    "WULING",
    "YAMAHA",
    "YUEJIN",
    "ZANELLA",
  ];
  
export const modelsByBrand:any = {
  "3-STAR": [
      "SXZ1045B",
      "SXZ1045BDW",
      "SXZ1047",
      "SXZ1047W",
      "SXZ3043"
  ],
  "ACURA": [
      "MDX"
  ],
  "AGRALE": [
      "10000",
      "13000",
      "14000",
      "6000",
      "6000D",
      "7500",
      "8500",
      "8500CD",
      "8700",
      "9200",
      "A 10000",
      "A 8700",
      "A10000",
      "A7500",
      "A8700",
      "CHASIS",
      "FURGOVAN",
      "MA",
      "MT"
  ],
  "AIXAM": [
      "CROSSLINE"
  ],
  "ALFA ROMEO": [
      "145",
      "146",
      "147",
      "156",
      "159",
      "166",
      "2000",
      "2600",
      "ALFA",
      "BRERA",
      "GIULIA",
      "GIULIETTA",
      "GT",
      "GTV",
      "GTV6",
      "MITO",
      "SPIDER",
      "STELVIO",
      "T.SPARK",
      "T_SPARK",
      "VELOCE"
  ],
  "APPIA": [
      "ANDINA",
      "BERAKA",
      "BLESS",
      "BREZZA",
      "CITIPLUS",
      "CITIPLUS110",
      "DCN",
      "GEREMY",
      "HARDWIND",
      "LEYENDA",
      "MONTERO",
      "REGIA",
      "STRONGER",
      "VECTRA"
  ],
  "APRILIA": [
      "APRILIA",
      "DORSODURO",
      "RSV4",
      "SHIVER",
      "STX150",
      "TUONO"
  ],
  "ARCTIC CAT": [
      "300",
      "ALTERRA",
      "DVX300",
      "WILDCAT",
      "XR"
  ],
  "ARO": [
      "10",
      "10.0",
      "10.1",
      "10.2",
      "10.3",
      "10.4",
      "10.8",
      "10.9"
  ],
  "ASIA": [
      "AM",
      "BUS",
      "COMBI",
      "R2",
      "TOPIC",
      "TOWER",
      "TOWNER"
  ],
  "AUDI": [
      "Q2",
      "A1",
      "A3",
      "A4",
      "A5",
      "A6",
      "A7",
      "A8",
      "ALLROAD",
      "AVANT",
      "E-TRON",
      "Q3",
      "Q5",
      "Q7",
      "Q8",
      "R8",
      "RS3",
      "RS4",
      "RS5",
      "RS6",
      "RS7",
      "S3",
      "S4",
      "S5",
      "S6",
      "S8",
      "SQ5",
      "TT",
      "TTS"
  ],
  "BAIC": [
      "D20",
      "X25",
      "X35",
      "X55",
      "X65"
  ],
  "BAJAJ": [
      "AVENGER",
      "BOXER",
      "DISCOVER",
      "DOMINAR",
      "PULSAR",
      "ROUSER",
      "ROUSER220F",
      "V 15",
      "XCD125"
  ],
  "BELAVTOMAZ": [
      "BTM"
  ],
  "BENELLI": [
      "180",
      "302",
      "502C",
      "752S",
      "CAFFENERO",
      "IMPERIALE",
      "LEONCINO",
      "SETA",
      "TNT",
      "TNT15",
      "TNT25",
      "TNT300",
      "TNT600GT",
      "TRK",
      "TRK251",
      "ZAFFERANO"
  ],
  "BETA": [
      "ARK",
      "ARROW",
      "BK150",
      "BS110-1",
      "EIKON",
      "ENDURO",
      "EURO",
      "FINDER",
      "FOUR-AE/90",
      "GRINDER",
      "LX250-8",
      "MINICROSS",
      "PANDA",
      "QM",
      "QM200GY",
      "QUADRA",
      "RR450",
      "RT250",
      "TEMPO",
      "ZT310R",
      "ZT310T",
      "ZT310V",
      "ZT310X"
  ],
  "BETAMOTOR": [
      "ADVENTURE",
      "AKVO",
      "ARROW",
      "BK",
      "BOY",
      "BS",
      "CAYMAN",
      "CHRONO",
      "ENDURO",
      "FOUR",
      "FOXTER",
      "GRINDER",
      "MASTER",
      "MINICROSS",
      "MOTARD",
      "NEOS",
      "PANDA",
      "RK6",
      "RR",
      "SCOOBY",
      "TEMPO",
      "TR",
      "URBA",
      "X-TRAINER",
      "ZONTES",
      "ZT310R",
      "ZT310T",
      "ZT310V",
      "ZT310X"
  ],
  "BLAC": [
      "BJ1041",
      "BJ1041C4DG",
      "BJ1041C4SG",
      "BJ1050",
      "BJ1061"
  ],
  "BLACKSTONE": [
      "BKS110",
      "BKS200",
      "BKS200S",
      "BKS250",
      "BKS250S",
      "BKS300",
      "BKS300S",
      "BKS50",
      "JX200ST-3",
      "JX250ST",
      "JX250ST-2",
      "JX250ST-4A",
      "JX50ST-4"
  ],
  "BMW": [
      "116",
      "116I",
      "118",
      "118D",
      "118I",
      "120D",
      "120I",
      "125",
      "130I",
      "135",
      "1M",
      "218I",
      "220",
      "220I",
      "300",
      "316",
      "316I",
      "318",
      "318I",
      "318TDS",
      "320",
      "320I",
      "323",
      "323I",
      "323TI",
      "324",
      "325",
      "325I",
      "328",
      "328CI",
      "328I",
      "330",
      "330I",
      "335I",
      "428I",
      "430I",
      "435I",
      "440I",
      "523",
      "525",
      "525I",
      "528",
      "530",
      "530D",
      "530I",
      "530IA",
      "535",
      "535I",
      "540",
      "540I",
      "540IA",
      "545I",
      "550I",
      "635",
      "645CI",
      "650",
      "650CI",
      "650I",
      "740",
      "740I",
      "745",
      "745IA",
      "750",
      "750LI",
      "C 600",
      "C650",
      "C650GT",
      "F 650",
      "F 700",
      "F 750",
      "F 800",
      "F 850",
      "F650",
      "F800ST",
      "G 310",
      "G 450",
      "G 650",
      "G310R",
      "G650",
      "G650GS",
      "GR",
      "K1200",
      "K1200GT",
      "K1200LT",
      "K1200S",
      "K1300GT",
      "K1300R",
      "K1600B",
      "K1600GTL",
      "M 140",
      "M 240",
      "M 3",
      "M 5",
      "M135",
      "M135I",
      "M2",
      "M235I",
      "M240I",
      "M3/M5",
      "M340I",
      "M4",
      "M440I",
      "M5",
      "M6",
      "M850I",
      "R 1100",
      "R 1150",
      "R 1250",
      "R 18",
      "R NINE",
      "R1100S",
      "R1150",
      "R1150GS",
      "R1150R",
      "R1200",
      "R1200C",
      "R1200CL",
      "R1200GS",
      "R1200R",
      "R1200RS",
      "R1200RT",
      "S1000R",
      "S1000RR",
      "S1000XR",
      "X1",
      "X2",
      "X3",
      "X4",
      "X5",
      "X6",
      "Z3",
      "Z4",
      "Z8",
      "640",
      "640I",
      "K1600GT"
  ],
  "BRAVA": [
      "ALPINA",
      "ALTINO",
      "APOLO",
      "AQUILA",
      "ATHENE",
      "BR200",
      "BR250",
      "DAYSTAR",
      "ELEKTRA",
      "LAZER",
      "NEPAL",
      "NEVADA",
      "TEXANA",
      "WINSTAR"
  ],
  "CADILLAC": [
      "ESCALADE"
  ],
  "CAN-AM": [
      "COMMANDER",
      "DEFENDER",
      "DS",
      "DS450",
      "MAVERICK",
      "OUTLANDER",
      "R SPYDER",
      "RENEGADE",
      "SPYDER"
  ],
  "CERRO": [
      "BIX",
      "CE",
      "CE125-12",
      "CE150-13",
      "CERRO",
      "CHAMICAL",
      "COLT",
      "COW",
      "DOT",
      "FIRE",
      "FLY",
      "FORMER",
      "LINK",
      "LINKCE-110",
      "MOV",
      "PRINCE",
      "R9",
      "ROUTER",
      "SCRATCH",
      "SPEED",
      "SUNNY",
      "XTREME"
  ],
  "CF MOTO": [
      "250NK",
      "CF250-A"
  ],
  "CFMOTO": [
      "300NK",
      "400NK",
      "650GT",
      "650MT",
      "650NK",
      "700CL-X",
      "800MT",
      "ATV",
      "CF500",
      "CF500-2",
      "CF500-2A",
      "CF500-5",
      "CF500-5A",
      "CF500-5B",
      "CF500-5C",
      "CF500-A",
      "CF500AU",
      "CFORCE",
      "UFORCE",
      "ZFORCE"
  ],
  "CHANGAN": [
      "CS15",
      "CS75",
      "M201",
      "MD201"
  ],
  "CHERY": [
      "ARRIZO",
      "BEAT",
      "FACE",
      "FULWIN",
      "IQ",
      "M11",
      "M12",
      "NEW",
      "QQ",
      "T11",
      "TIGGO",
      "SKIN"
  ],
  "CHEVROLET": [
      "14000",
      "1500",
      "15-190",
      "16-220",
      "2500",
      "6000",
      "6-150",
      "AGILE",
      "ASTRA",
      "ASTRO",
      "AVALANCHE",
      "AVEO",
      "BLAZER",
      "C 1500",
      "C.60",
      "C10",
      "CAMARO",
      "CAPTIVA",
      "CAVALIER",
      "CELTA",
      "CHEVROLET",
      "CHEVY",
      "CLASSIC",
      "COBALT",
      "CORSA",
      "CORVETTE",
      "CRUZE",
      "CS",
      "D-14000",
      "EQUINOX",
      "G.",
      "GEO",
      "GMT",
      "GRAND",
      "IMPALA",
      "K 1500",
      "K1500",
      "KALOS",
      "KODIAC",
      "KODIAK",
      "LUV",
      "LUV.TRF/TFS/16",
      "LUV-TFS52H",
      "MALIBU",
      "MATIZ",
      "MERIVA",
      "MONTANA",
      "N 300",
      "NKR",
      "NPR",
      "NPR66PL-5LXY",
      "NUBIRA",
      "ONIX",
      "PICK-UP",
      "PRISMA",
      "S 10",
      "S-10",
      "S10",
      "SILVERADO",
      "SONIC",
      "SPARK",
      "SPIN",
      "SUBURBAN",
      "TAHOE",
      "TIGRA",
      "TRACKER",
      "TRAIL",
      "TRAILBLAZER",
      "TRAVERSE",
      "TROOPER",
      "UPLANDER",
      "VECTRA",
      "VENTURE",
      "VITARA",
      "ZAFIRA"
  ],
  "CHRYSLER": [
      "300",
      "300C",
      "CARAVAN",
      "CHEROKEE",
      "CHEROKEE/LAREDO/SPORT/T",
      "CROSSFIRE",
      "DAKOTA",
      "G.CHEROKEE/LAREDO/LIMITE",
      "GRAND",
      "JEEP",
      "NEON",
      "PACIFICA",
      "PT",
      "RAM",
      "SEBRING",
      "STRATUS",
      "TOWN",
      "VOYAGER",
      "WRANGLER"
  ],
  "CITROEN": [
      "BERFUD6",
      "BERFUD6PL",
      "BERFUD6PLDA",
      "BERFUD6PLDAA",
      "BERFUD8PLDA",
      "BERFUD8PLDAA",
      "BERLINGO",
      "BERLINGO2.0",
      "BERMUDPLDAA",
      "BERMUDPLDAB",
      "BERMUL2D",
      "BERMUL2DAB",
      "BERMUL2N",
      "BERMUL2NAB",
      "BERMUNPLDAA",
      "BERMUNPLDAB",
      "C 15",
      "C-",
      "C.15",
      "C.15D/E/RD",
      "C1",
      "C15",
      "C2",
      "C3",
      "C4",
      "C5",
      "C6",
      "C-ELYSEE",
      "C-ELYS",
      "DS",
      "DS3",
      "DS4",
      "GRAND",
      "JUMPER",
      "JUMPY",
      "NUEVO",
      "SAXO",
      "SPACETOURER",
      "XANTIA",
      "XANTIA/TD/I/16V",
      "XSARA",
      "ZX"
  ],
  "CORADIR": [
      "S2-100",
      "S2-100AA"
  ],
  "CORVEN": [
      "DX",
      "ENERGY",
      "EXPERT",
      "HUNTER",
      "INDIANA",
      "LX",
      "MIRAGE",
      "TERRAIN",
      "TRIAX",
      "TXR"
  ],
  "DA DALT": [
      "CHAT",
      "CICLOCARGA",
      "DS",
      "SIGNIA"
  ],
  "DACIA": [
      "BERLINA",
      "BREAK",
      "DOKKER",
      "DUSTER",
      "LOGAN",
      "R1.6",
      "R524",
      "SANDERO"
  ],
  "DAELIM": [
      "BZ1",
      "DAYSTAR",
      "QL",
      "SZ2"
  ],
  "DAEWOO": [
      "CIELO",
      "CIELO/GLE/GL",
      "DAMAS",
      "ESPERO",
      "ISTANA",
      "LABO",
      "LANOS",
      "LEGANZA",
      "MATIZ",
      "MUSSO",
      "NUBIRA",
      "TICO",
      "KALOS"
  ],
  "DAIHATSU": [
      "APPLAUSE",
      "CUORE",
      "DELTA",
      "ECODELTA",
      "F300",
      "FEROZA",
      "GRAND",
      "HIJET",
      "M.CUORE",
      "MIRA",
      "MOVE",
      "ROCKY",
      "SIRION",
      "TERIOS"
  ],
  "DAIMLER BENZ": [
      "LP"
  ],
  "DAYAMA": [
      "DA-250ST"
  ],
  "DEUTZ": [
      "STALLION",
      "STALLION-3",
      "STALLION-4"
  ],
  "DEUTZ AGRALE": [
      "DINAMIC-13",
      "DYNAMIC-01",
      "DYNAMIC-02",
      "DYNAMIC-03",
      "DYNAMIC-04",
      "DYNAMIC-06",
      "DYNAMIC-10",
      "DYNAMIC-11",
      "DYNAMIC-12",
      "DYNAMIC-13",
      "DYNAMIC-14",
      "STARK-",
      "STARK-15",
      "STARK-16",
      "STARK-17",
      "STARK-18"
  ],
  "DEUTZ-AGRALE": [
      "STARK"
  ],
  "DFM": [
      "1064",
      "1216",
      "1216-E5",
      "DUOLICA",
      "T01"
  ],
  "DFSK": [
      "C31",
      "C32",
      "C35",
      "K01H"
  ],
  "DIMEX": [
      "2625",
      "433-160",
      "451-160",
      "551-175",
      "551-190",
      "551-250",
      "55125002",
      "7400-370",
      "74-310",
      "91-370",
      "D 1416",
      "D 1422",
      "D 1621",
      "D 1721",
      "D 1725",
      "D 1730",
      "D 2730"
  ],
  "DINA": [
      "433-160"
  ],
  "DODGE": [
      "B2500",
      "CALIBER",
      "CARAVAN",
      "CARAVAN/SE/ES/LE",
      "CHALLENGER",
      "DAKOTA",
      "DURANGO",
      "FORZA",
      "GRAND",
      "JOURNEY",
      "NEON",
      "NEON/SPORT",
      "NITRO",
      "RAM",
      "RAM2500",
      "STRATUS",
      "STRATUS/LX",
      "VIPER"
  ],
  "DONGFENG": [
      "MINI"
  ],
  "DS": [
      "DS",
      "DS3"
  ],
  "DUCATI": [
      "1199",
      "1299",
      "748",
      "748R",
      "749",
      "899",
      "999",
      "DIAVEL",
      "HYPERMOTARD",
      "M750",
      "MONSTER",
      "MULTISTRADA",
      "PANIGALE",
      "PAUL",
      "SCRAMBLER",
      "SPORT",
      "ST3",
      "STREETFIGHTER",
      "SUPER",
      "SUPERSPORT",
      "XDIAVEL"
  ],
  "ELITE": [
      "ELITE"
  ],
  "ELPRA ELECTRIC": [
      "CITY-M8-CITY",
      "FOLK/ZMAJ05",
      "INDIE",
      "MASTER",
      "TRES",
      "TWIST/LX05/TW"
  ],
  "EUROMOT": [
      "GTX250B",
      "HJ110-2",
      "HJ125-7",
      "HJ125T-11A"
  ],
  "FAW": [
      "ACTIS",
      "T33",
      "X40"
  ],
  "FERESA": [
      "AV-120",
      "TITA"
  ],
  "FERRARI": [
      "330",
      "360",
      "430",
      "456",
      "458",
      "488",
      "550",
      "599",
      "CALIFORNIA",
      "F12",
      "F355",
      "F430",
      "F599",
      "PORTOFINO"
  ],
  "FIAT": [
      "16V",
      "185",
      "330",
      "330.26",
      "500",
      "500L",
      "500X",
      "ARGO",
      "BARCHETTA",
      "BRAVA",
      "BRAVO",
      "CROMA",
      "CRONOS",
      "DOBLO",
      "DUCATO",
      "DUNA",
      "ELBA",
      "EUROCARGO",
      "FIORINO",
      "GRAND",
      "IDEA",
      "IVECO",
      "LINEA",
      "MAREA",
      "MOBI",
      "MULTIPLA",
      "NUEVO",
      "PALIO",
      "PANDA",
      "PREMIO",
      "PREMIO/CS",
      "PULSE",
      "PUNTO",
      "QUBO",
      "SCUDO",
      "SIENA",
      "STILE",
      "STILO",
      "STRADA",
      "TIPO",
      "TORO",
      "UNO",
      "VETRATO",
      "WEEKEND",
      "WORKING"
  ],
  "FIAT IVECO": [
      "170",
      "330-26",
      "330-30",
      "330-35"
  ],
  "FORD": [
      "150",
      "8000",
      "BRONCO",
      "C-1517",
      "CARGO",
      "CF8000",
      "COURIER",
      "D 900-88",
      "D-1614",
      "ECOSPORT",
      "EDGE",
      "ESCAPE",
      "ESCORT",
      "EXCURSION",
      "EXPEDITION",
      "EXPLORER",
      "EXPLORER/XLT",
      "F 150",
      "F 250",
      "F 350",
      "F-100",
      "F100",
      "F-100/88",
      "F100/F101",
      "F-1000",
      "F-14.000",
      "F-14000/HD",
      "F150",
      "F-150",
      "F-250",
      "F250",
      "F350",
      "F-350",
      "F350LARIAT",
      "F400",
      "F-4000",
      "F-4000/D",
      "F-450",
      "F600",
      "F-800",
      "FESTIVA",
      "FIESTA",
      "FOCUS",
      "FREESTAR",
      "F-SUPER",
      "FUSION",
      "GHIA",
      "KA",
      "KUGA",
      "L 8000",
      "L8501",
      "L8513",
      "LN",
      "LN/LNT",
      "LNT",
      "LT",
      "LTS",
      "MAVERICK",
      "MONDEO",
      "MUSTANG",
      "NEW",
      "NUEVA",
      "PUMA",
      "RANGER",
      "RANGER/XL",
      "RANGER2",
      "S-",
      "S-MAX",
      "TAURUS",
      "TERRITORY",
      "TOURNEO",
      "TRANSIT",
      "TRANSIT2.2L"
  ],
  "FOTON": [
      "AUMARK",
      "GRATOUR",
      "TM1",
      "TUNLAND",
      "VIEW"
  ],
  "GAC GONOW": [
      "GA6420CL"
  ],
  "GAF": [
      "GX"
  ],
  "GALLOPER": [
      "DSL",
      "EXCEED",
      "INNOVATION",
      "SANTAMO",
      "TURBO",
      "XL"
  ],
  "GAMMA": [
      "CFORCE",
      "EAGLE",
      "MOUNTAINEER",
      "UFORCE",
      "VANGUARD",
      "ZFORCE"
  ],
  "GARELLI SAHEL": [
      "SAHEL"
  ],
  "GAZ": [
      "22171",
      "2705-24",
      "2705-44",
      "2752",
      "322132",
      "3302",
      "33023",
      "33027",
      "3307",
      "3309",
      "33097",
      "66-40"
  ],
  "GEELY": [
      "515",
      "EMGRAND",
      "LC"
  ],
  "GEO": [
      "TRACKER"
  ],
  "GHIGGERI": [
      "VITA"
  ],
  "GILERA": [
      "AC4",
      "AG50",
      "AX100",
      "C 110",
      "C100",
      "C110",
      "C50",
      "C70",
      "CD",
      "EG2",
      "FR",
      "FR110",
      "FR-110",
      "FR-125",
      "FR150",
      "FR-150",
      "FR200",
      "FR-200",
      "FR-200R",
      "FR-250",
      "FR250",
      "FREE",
      "FR-X",
      "FR-X250",
      "FRX300",
      "FU",
      "FUTURA",
      "FX-125",
      "G1",
      "GA",
      "GA125",
      "GA-125",
      "GA-50",
      "GLA",
      "GLA100",
      "GLA-110",
      "GX1",
      "JL150",
      "LC",
      "LM-50",
      "LX100-3A",
      "LX100-3B",
      "QAX100",
      "QAX110-4T",
      "QAX-125",
      "QM",
      "QM100-12",
      "QM100-7B",
      "QM100-9",
      "QM110-7B",
      "QM110-9",
      "QM125-10",
      "QM125-11A",
      "QM125-7B",
      "QM125T-10A",
      "QM125T-10D",
      "QS90-2",
      "SAHEL",
      "SG",
      "SMASH",
      "SMX",
      "SMX400",
      "SPEEDY",
      "SUPER",
      "VC",
      "VC125",
      "VC150",
      "VC250",
      "VC650",
      "VC-R",
      "VOGE",
      "XKODIAC",
      "YL",
      "YL125T-3",
      "YL200"
  ],
  "GMC": [
      "ACADIA",
      "JIMMY",
      "KODIAK",
      "SAVANA",
      "SIERRA",
      "SUBURBAN",
      "YUKON"
  ],
  "GREAT WALL": [
      "H6",
      "HAVAL",
      "POER",
      "WINGLE"
  ],
  "GUERRERO": [
      "ARGEN-CARGO",
      "G 50",
      "G100",
      "G110",
      "G110DB",
      "G110DL",
      "G125",
      "G3R200",
      "G50",
      "G50AE",
      "G90",
      "GC",
      "GC125",
      "GC125QUEEN",
      "GC150",
      "GC200",
      "GE",
      "GFT110",
      "GFT150",
      "GFT200",
      "GFT250",
      "GFT300",
      "GFT350X",
      "GK110",
      "GMX",
      "GMX250",
      "GN110",
      "GPR",
      "GPR250",
      "GR1",
      "GR5",
      "GR6",
      "GRF250",
      "GRF250X",
      "GRF70",
      "GRF90",
      "GRM",
      "GSL",
      "GSL150",
      "GT",
      "GT110",
      "GT70",
      "GVL400",
      "GXL",
      "GXL150",
      "GXM200",
      "GXR",
      "GXR200",
      "GXR250",
      "GXR300",
      "WG"
  ],
  "HAOJUE": [
      "NK"
  ],
  "HARLEY DAVIDSON": [
      "1200",
      "DYNA",
      "E.CLIDE",
      "ELECTRA",
      "FAT",
      "FL",
      "FLFB",
      "FLFBS",
      "FLH",
      "FLHP",
      "FLHR",
      "FLHRC",
      "FLHRCI",
      "FLHRI",
      "FLHRS",
      "FLHRSI",
      "FLHT",
      "FLHTC",
      "FLHTCI",
      "FLHTCU",
      "FLHTCUI",
      "FLHTK",
      "FLHX",
      "FLHXS",
      "FLS",
      "FLSL",
      "FLSTC",
      "FLSTF",
      "FLSTFB",
      "FLSTFBS",
      "FLSTFI",
      "FLSTN",
      "FLSTNI",
      "FLSTS",
      "FLSTSE",
      "FLTRU",
      "FLTRXS",
      "FXBB",
      "FXBRS",
      "FXCWC",
      "FXD",
      "FXDB",
      "FXDBI",
      "FXDC",
      "FXDCI",
      "FXDF",
      "FXDL",
      "FXDR",
      "FXDSCN",
      "FXDWG",
      "FXDX",
      "FXDXI",
      "FXSB",
      "FXST",
      "FXSTB",
      "FXSTBI",
      "FXSTC",
      "FXSTD",
      "FXSTI",
      "FXSTS",
      "NIGTH",
      "ROAD",
      "SOFTAIL",
      "SPORSTER",
      "SPORTER",
      "SPORTSTER",
      "V-ROD",
      "VRSCA",
      "VRSCAW",
      "VRSCB",
      "VRSCDX",
      "VRSCR",
      "XG",
      "XL",
      "XL1200C",
      "XL1200N",
      "XL1200NS",
      "XL1200R",
      "XL1200T",
      "XL1200V",
      "XL1200X",
      "XL883C",
      "XL883L",
      "XL883N",
      "XL883R",
      "XLH"
  ],
  "HAVAL": [
      "H1",
      "H2",
      "H6",
      "JOLION"
  ],
  "HEI BAO": [
      "HB1605",
      "HB1605-6"
  ],
  "HERO": [
      "DASH",
      "HERO",
      "HUNK",
      "IGNITOR",
      "XPULSE"
  ],
  "HINO": [
      "300",
      "300/",
      "500",
      "XZU720L"
  ],
  "HONDA": [
      "301CBR600F3",
      "ACCORD",
      "AFRICA",
      "BIZ",
      "C100",
      "C100P",
      "C105",
      "C70",
      "CB",
      "CB1",
      "CB1000",
      "CB125F",
      "CB500F",
      "CB500X",
      "CB600F",
      "CB919",
      "CBR",
      "CBR1100",
      "CBR1100X",
      "CBR1100XV",
      "CBR1100XX",
      "CBR300RA",
      "CBR600",
      "CBR600F2",
      "CBR600F2N",
      "CBR600F3",
      "CBR600F4",
      "CBR600FM",
      "CBR600FV",
      "CBR600FV-ED",
      "CBR600RR",
      "CBR600RR3",
      "CBR900",
      "CBR900RE",
      "CBR900RR",
      "CBR900RRL",
      "CBR900RRN",
      "CBR900RRV",
      "CBR900RRW-ED",
      "CBR929RR",
      "CBR954RR",
      "CBX250",
      "CG",
      "CG125",
      "CG-125",
      "CG150",
      "CG150ESD",
      "CG150KS",
      "CGL125",
      "CH",
      "CITY",
      "CIVIC",
      "CR",
      "CR125",
      "CR-125",
      "CR125R",
      "CR125RM",
      "CR125RT",
      "CR-250",
      "CR250R",
      "CR250RP",
      "CR250RT",
      "CR250RV",
      "CR500",
      "CR500R",
      "CR500RM",
      "CR80R",
      "CR80RB",
      "CR80RP",
      "CR80RR",
      "CR80RS",
      "CR80RT",
      "CR80S",
      "CR85R",
      "CRF",
      "CRF1000L",
      "CRF100F",
      "CRF150R",
      "CRF250L",
      "CRF450X",
      "CRF50F",
      "CRF50FE",
      "CRF50FG",
      "CRF70F",
      "CRF80F",
      "CRV",
      "CR-V",
      "CRV-EX",
      "CRVI",
      "ELITE",
      "FALCON",
      "FIT",
      "FOURTRAX",
      "GLH",
      "HR-V",
      "HURRICANE",
      "INVICTA",
      "LEAD",
      "LEGEND",
      "NC700X",
      "NC750X",
      "NC750XA",
      "NC750XD",
      "NEW",
      "NF",
      "NF100",
      "NT700VA",
      "NX4",
      "NX400",
      "NXR125",
      "ODYSSEY",
      "PCX",
      "PILOT",
      "POP100",
      "PRELUDE",
      "RINCON",
      "SDH",
      "SDH125",
      "SDH125-46",
      "SDH125T",
      "SHADOW",
      "STORM",
      "STREAM",
      "TRANSA",
      "TRANSALP",
      "TRX",
      "TRX250D",
      "TRX250ES",
      "TRX250EX",
      "TRX250TE",
      "TRX250TM",
      "TRX250-X",
      "TRX350",
      "TRX350FE",
      "TRX350TE",
      "TRX350TM",
      "TRX400EX",
      "TRX400FGA",
      "TRX400X",
      "TRX420FA",
      "TRX420FA6",
      "TRX420FE",
      "TRX420FM",
      "TRX420FM1",
      "TRX420FPA",
      "TRX420FPE",
      "TRX420FPM",
      "TRX420PFA",
      "TRX420TE",
      "TRX420TE7",
      "TRX420TM1",
      "TRX450",
      "TRX450ER",
      "TRX450FE",
      "TRX450FM",
      "TRX450R",
      "TRX500",
      "TRX500FA",
      "TRX500FE",
      "TRX500FGA",
      "TRX500FPA",
      "TRX500FPE",
      "TRX500FPM",
      "TRX500FW",
      "TRX500TM6",
      "TRX520FM1",
      "TRX650FA",
      "TRX680FA",
      "TRX680FG",
      "TRX680FGA",
      "V MEN",
      "VARADERO",
      "VFR800X",
      "VLX",
      "VT",
      "VT1100",
      "VT1100C",
      "VT750",
      "VT750C2",
      "VT750C2W-ED",
      "VT750CD2",
      "VT750CDA",
      "VT750CDB",
      "VT750CDC",
      "VT750DC",
      "VT750DCB",
      "VTX1300",
      "WAVE",
      "WR-V",
      "XL1000V",
      "XL200",
      "XL200R",
      "XL650V",
      "XL700V",
      "XL700VA",
      "XLR",
      "XR",
      "XR125L",
      "XR250",
      "XR250L",
      "XR250LR",
      "XR250R",
      "XR250RR",
      "XR250RT",
      "XR250RV",
      "XR650L",
      "XRE",
      "XRE300",
      "XRV750",
      "XRV750V",
      "XRV750V-ED",
      "TRX680FAC"
  ],
  "HUMMER": [
      "H2"
  ],
  "HUSQVARNA": [
      "701",
      "FC",
      "FC250",
      "FE",
      "FX",
      "HQV",
      "NORDEN",
      "SVARTPILEN",
      "TC",
      "TE",
      "TE250",
      "TX",
      "TXC250R",
      "VITPILEN",
      "VITPLEN"
  ],
  "HYUNDAI": [
      "ACCENT",
      "AERO",
      "ATOS",
      "ATOZ",
      "AVANTE",
      "CHORUS",
      "COUNTY",
      "COUPE",
      "CRETA",
      "ELANTRA",
      "EURO",
      "FX",
      "GALLOPER",
      "GENESIS",
      "GETZ",
      "GRACE",
      "GRAND",
      "H 100",
      "H 250",
      "H1",
      "H-1",
      "H100",
      "H350",
      "HD",
      "HD35",
      "HD65",
      "HD72",
      "HD78",
      "I 10",
      "I 30",
      "I10",
      "I30",
      "IONIQ",
      "KONA",
      "LANTRA",
      "MATRIX",
      "MIGHTY",
      "NEW",
      "PORTER",
      "PRO",
      "SANTA",
      "SANTAMO",
      "SONATA",
      "STAREX",
      "STARIA",
      "TERRACAN",
      "TIBURON",
      "TRAJET",
      "TUCSON",
      "VELOSTER",
      "VERACRUZ",
      "VERNA"
  ],
  "IMSA": [
      "EVO",
      "IM",
      "IM110",
      "IM110E",
      "IM110T",
      "IM125",
      "IM125R.4",
      "IM125T",
      "IM150",
      "ROAD"
  ],
  "INDIAN": [
      "CHIEF",
      "SCOUT"
  ],
  "INFINITI": [
      "FX"
  ],
  "INNOCENTI": [
      "ELBA"
  ],
  "INTERNACIONAL": [
      "4700",
      "9670"
  ],
  "INTERNATIONAL": [
      "2500",
      "2554",
      "2654",
      "2674",
      "4300",
      "4600",
      "4700",
      "4800",
      "4900",
      "4954",
      "8100",
      "8200",
      "8300",
      "9200",
      "9300",
      "9600",
      "9670",
      "9700",
      "9800",
      "9900",
      "CO9600",
      "COF",
      "MSC",
      "S1800",
      "S1900",
      "S2500",
      "TRANSTAR"
  ],
  "ISUZU": [
      "2.5",
      "2.8",
      "4X2",
      "AMIGO",
      "AXIOM",
      "B241",
      "CHR660",
      "DMAX",
      "D-MAX",
      "DOBLE",
      "NHR",
      "NKR",
      "NPR",
      "NPR65LL3.9D",
      "NPR65PL3.9D",
      "NPR70",
      "NPR75",
      "NQR90",
      "RODEO",
      "SPACE",
      "TFR16H-00",
      "TFR54H",
      "TFR54H-00",
      "TFR54H-10",
      "TFR54H-20",
      "TFR54H-20/00",
      "TFR54HDAL",
      "TFR54HPAL",
      "TFR54HSAL",
      "TFR55H-00",
      "TFR55H-10",
      "TFR55H-20",
      "TFR55HDAL",
      "TFR55HPAL",
      "TFR55HSAL",
      "TFR69HDEI",
      "TFR69HDI",
      "TFR69HPEI",
      "TFR69HSEI",
      "TFR69HSI",
      "TFS",
      "TFS54F-00",
      "TFS54H-10",
      "TFS54H-20",
      "TFS55H-00",
      "TFS55H-10",
      "TFS55H10",
      "TFS55H-20",
      "TFS55H20",
      "TFS55HDAL",
      "TFS55HSAL",
      "TFS69-HD",
      "TFS69HDE",
      "TFS69HDEI",
      "TFS69HPEI",
      "TFS69HSEI",
      "TROOPER",
      "TROPPER",
      "TURBO",
      "VEHICROSS"
  ],
  "IVECO": [
      "120",
      "120E15",
      "140E18",
      "150E20N/150E20T",
      "150E21NCMB1A",
      "150E21NCMC1A",
      "160E21CCM32",
      "160E21NCM32",
      "160E21NCM52",
      "160E23N/160E23T",
      "170E21",
      "170E22",
      "170E22NCM21",
      "170E22NCM31",
      "170E22NCM51",
      "170E22NLM31",
      "170E22NLM51",
      "170E22RCM31",
      "170E22T",
      "170E22TLM22",
      "170E22TLM2B",
      "170E25",
      "170E25T",
      "170E28",
      "170E28NCM22",
      "170E28NCM32",
      "170E28NCM51",
      "170E28T",
      "170E28TLM21",
      "170E30NLA31",
      "170E30NLA52",
      "170E30TLA21",
      "170E30TLA22",
      "170S28",
      "180C33",
      "180E32",
      "180E33",
      "200",
      "200E37",
      "200S36",
      "200S36NCA43",
      "200S36NLA43",
      "200S38",
      "200S41",
      "200S42",
      "200S44",
      "200S44N",
      "230E22",
      "230E24",
      "240E25",
      "240E28",
      "240E28N2M53",
      "240E28NCA63",
      "240E28NCM53",
      "240E28NCM63",
      "240E28NLM54",
      "240E28NLM63",
      "240E28NLM73",
      "240E28RCA33",
      "240E28RCA63",
      "240E30NLA63",
      "240E30NLA73",
      "256",
      "260",
      "260-23",
      "260-25",
      "260-30",
      "260E25",
      "260E28",
      "260E28NCM53",
      "260E30NCM24",
      "260E30NCM54",
      "320-30",
      "320-32",
      "320E18T",
      "35-8",
      "380",
      "380E31",
      "380E37H",
      "380T38",
      "380T42",
      "40.10",
      "40.10/49",
      "400E37",
      "410T44N2M46",
      "410T44NCA16",
      "410T44NCM16",
      "410T44NLA46",
      "430E31T",
      "450",
      "450C33T",
      "450E32T",
      "450E32TY",
      "450E33T",
      "450E33TY",
      "450E37T",
      "450E37T/HR",
      "450E37T/P",
      "450E42TZ",
      "450S38D",
      "450S38T/HR",
      "450S38T/P",
      "450S42T",
      "450S42T/HR",
      "450S42T/P",
      "460S36",
      "460S36T",
      "460S36TLA14",
      "49.10",
      "490S38T",
      "490S38T/P",
      "490S39T",
      "490S41T",
      "490S42T",
      "490S42T/P",
      "490S44T",
      "490S46T",
      "530S36T",
      "530S36TLA85A",
      "570S38T",
      "570S41T",
      "570S42T",
      "570S46T",
      "600S44TLA05",
      "600S44TLA15",
      "70C14G",
      "720E42HT",
      "720T42T",
      "740S41T",
      "740S41TZ",
      "740S42TZ",
      "740T48TLA17",
      "740T48TLM47",
      "80",
      "800S48TZ",
      "90E14",
      "AD410T45",
      "AS440S48T/P",
      "AS600S44TYPA85",
      "AT600S44TYPA85",
      "CC170E22",
      "CC90E14",
      "CURSOR",
      "D1T1A4C8-STRALIS",
      "DAILY",
      "DAILY50C17FURGONVIDRIAD",
      "DC4910",
      "DC4910S",
      "DUCATO",
      "EURO",
      "EUROCARGO",
      "EUROTECH",
      "EUROTRAKKER",
      "HD",
      "M 40.10",
      "MASSIF",
      "MH",
      "ML",
      "MLL",
      "MP190E37W",
      "MP380E37H",
      "MP410E44H/80",
      "MP440E42T",
      "MP720E37HT",
      "R7T4B3B8-STRALIS",
      "STRALIS",
      "TECTOR",
      "TRAKKER",
      "TUBO",
      "TURBO",
      "VERTIS"
  ],
  "JAC": [
      "HFC1035K",
      "HFC1041K",
      "HFC1061K",
      "JAC",
      "S2",
      "S3",
      "S5",
      "S7",
      "T6",
      "X200"
  ],
  "JAGUAR": [
      "DAIMLER",
      "F-PACE",
      "F-TYPE",
      "SOVEREIGN",
      "S-TYPE",
      "VANDENPLAS",
      "XE",
      "XF",
      "XFR",
      "XJ",
      "XJ8",
      "XJR",
      "XJR8",
      "XJS",
      "XK8",
      "XKE",
      "XKR",
      "XKR8",
      "X-TYPE"
  ],
  "JAWA": [
      "CZ",
      "DAYTONA",
      "DD",
      "JAWA",
      "RVM",
      "SPYDER",
      "SUPERNOVA",
      "TEKKEN"
  ],
  "JEEP": [
      "CHEROKEE",
      "CHEROKKE",
      "COMMANDER",
      "COMPASS",
      "GLADIATOR",
      "GR.",
      "GRAND",
      "LIBERTY",
      "PATRIOT",
      "RENEGADE",
      "WRANGLER",
      "WRANGLER/4.0L"
  ],
  "JETOUR": [
      "X70"
  ],
  "JIANSHE": [
      "JS",
      "JS110-3",
      "JS110-3H",
      "JS110ATV",
      "JS110-C",
      "JS125",
      "JS125-3",
      "JS125-5B",
      "JS125-6B",
      "JS125-7",
      "JS125F",
      "JS150-3",
      "JS250ATV-3",
      "JS250ATV-5",
      "JS250ATV-F2",
      "JS400ATV"
  ],
  "JINCHENG": [
      "JC",
      "JC110-9",
      "JC125-12",
      "JC125-17B",
      "JC250-6"
  ],
  "JMC": [
      "CONQUER",
      "N 900",
      "N-601",
      "N800"
  ],
  "JMSTAR": [
      "ALPINA",
      "NEVADA"
  ],
  "KAWASAKI": [
      "BAYOU",
      "BRUTE",
      "CONCOURS",
      "ELIMINATOR",
      "EN",
      "EN500",
      "EN500-A3",
      "EN500-A5",
      "EN500-A6L",
      "EN500A7",
      "EN500B2",
      "EN500-BI",
      "EN500-C",
      "EN500-C1",
      "EN500-C2",
      "ER-6N",
      "EX",
      "EX250",
      "EX250-F",
      "EX250-F10",
      "EX250-F12",
      "EX250-F6",
      "EX250-F6L",
      "EX250-F7",
      "EX250-F8",
      "EX250-F8L",
      "EX250F9",
      "EX250-F9L",
      "EX250-II3",
      "KE100-B11",
      "KFX",
      "KFX450R",
      "KFX50",
      "KFX700",
      "KFX90",
      "KLE650",
      "KLF250A6F",
      "KLF250A8F",
      "KLF250A9F",
      "KLF250ABF",
      "KLR",
      "KLX",
      "KLX110",
      "KLX250",
      "KLX250-D1",
      "KLX250-D4",
      "KLX250-E1",
      "KLX250SF",
      "KLX450A8F",
      "KLX450R",
      "KSF450B8F",
      "KSF450B9F",
      "KSF450BBF",
      "KSF50",
      "KSF50-A3",
      "KSF50BDF",
      "KVF400",
      "KVF400-A1",
      "KVF400-A2",
      "KVF400-B1",
      "KVF400-C",
      "KVF400-C1",
      "KVF400-C2",
      "KVF650",
      "KVF650DCS",
      "KVF650F9F",
      "KVF750",
      "KVF750D8F",
      "KVF750DAF",
      "KVF750DBF",
      "KVF750FBF",
      "KVF750GSC",
      "KVF750HCF",
      "KVF750LCF",
      "KX",
      "KX250",
      "KX250F",
      "KX250-K1",
      "KX250-K3",
      "KX250L",
      "KX250-N2",
      "KX250W9F",
      "KX450F",
      "KX80-W",
      "KX85",
      "LF250A",
      "NINJA",
      "PRAIRE",
      "PRAIRIE",
      "TERYX",
      "TERYX4",
      "VERSYS",
      "VERSYS-X",
      "VN",
      "VN2000",
      "VN2000-A",
      "VN900",
      "VULCAN",
      "Z 400",
      "Z1000",
      "Z650",
      "Z750",
      "Z800",
      "Z900",
      "Z900RS",
      "ZR",
      "ZR1100",
      "ZR750-C3",
      "ZX",
      "ZX10",
      "ZX1000",
      "ZX1000-C",
      "ZX-10R",
      "ZX1200-B",
      "ZX14",
      "ZX600",
      "ZX600-C5",
      "ZX600-D2",
      "ZX600-E3",
      "ZX600-E4",
      "ZX600-F",
      "ZX600-F1",
      "ZX600-F2",
      "ZX600-F3",
      "ZX600-F3L",
      "ZX600-G",
      "ZX600R",
      "ZX636-B",
      "ZX636C",
      "ZX-6R",
      "KVF750GCS",
      "KVF750HCS",
      "KVF750JCF"
  ],
  "KEEWAY": [
      "KLIGHT",
      "RK",
      "TARGET",
      "TX"
  ],
  "KELLER": [
      "CONQUISTA",
      "JET",
      "K65",
      "K65-MT",
      "K65-TR",
      "KN110-10",
      "KN110-7",
      "KN110-8",
      "KN110-9",
      "KN125-11",
      "KN125-12",
      "KN150-13",
      "KN-150-13",
      "KN150-3",
      "KN150GY",
      "KN200GY",
      "KN200R",
      "KN250-3",
      "KN250GY",
      "KN260GY",
      "KR150S",
      "KR200GY",
      "KR200ST-6",
      "KR250ST-8",
      "KR250-STIXE",
      "KR260",
      "KR260A/",
      "KR260GY",
      "STRATUS",
      "XTREME",
      "YB",
      "YB150T",
      "YB150T-10",
      "YB150T-12",
      "YB150T-15",
      "YB150T-2",
      "YB150T-5",
      "YB150T-7",
      "YBR260"
  ],
  "KIA": [
      "AVELLA",
      "BESTA",
      "CARENS",
      "CARNIVAL",
      "CEE",
      "CERATO",
      "CERES",
      "CLARUS",
      "COMBI",
      "FRONTIER",
      "GRAND",
      "GTX",
      "HI-BESTA",
      "K 2900",
      "K 3500",
      "K 3600S",
      "K-2400",
      "K2400",
      "K2500",
      "K-2700",
      "K2700",
      "K3500",
      "K3600",
      "K3600S",
      "MAGENTIS",
      "MOHAVE",
      "MORNING",
      "OPIRUS",
      "PICANTO",
      "PREGIO",
      "PRIDE",
      "RHINO",
      "RIO",
      "RONDO",
      "SEDONA",
      "SELTOS",
      "SEPHIA",
      "SORENTO",
      "SOUL",
      "SPORTAGE",
      "TOPIC",
      "TRADE"
  ],
  "KIDEN": [
      "KD"
  ],
  "KIKAI": [
      "DK",
      "DK125",
      "DK150",
      "DK250"
  ],
  "KONISA": [
      "CL",
      "CL125-15",
      "KE125-26",
      "KE125-28H",
      "KE200",
      "KE250",
      "LF125-J",
      "LX",
      "LX110-3A",
      "LX150-6A",
      "QS110-5",
      "QS200-8",
      "YJ-110B",
      "YJ-150B",
      "YJ-250B",
      "YJ-250D"
  ],
  "KTM": [
      "1090",
      "1190",
      "125",
      "125SX",
      "1290",
      "150",
      "200",
      "200EXC",
      "250",
      "250EXC",
      "250EXC-F",
      "300",
      "350",
      "390",
      "450",
      "450SXF",
      "50",
      "500",
      "50SX",
      "530",
      "65",
      "690",
      "790",
      "85",
      "950",
      "990",
      "ADVENTURE",
      "DUKE",
      "KTM",
      "MOTO",
      "RC"
  ],
  "KYC": [
      "MAMUT",
      "X3"
  ],
  "KYMCO": [
      "ACTIV",
      "AGILITY",
      "AK",
      "AXR110",
      "BET",
      "DINK",
      "DJ",
      "DJ50",
      "DOWNTOWN",
      "GRAND",
      "HEROISM",
      "HIPSTER",
      "KB",
      "LIKE",
      "MXER",
      "MXU",
      "MXU375",
      "PEOPLE",
      "PLEASURE",
      "PULSAR",
      "QUANNON",
      "SPIKE",
      "STRYKER",
      "SUPER",
      "SUPER9",
      "TOP",
      "UXV",
      "UXV500",
      "VENOX",
      "VISA",
      "XCITING",
      "XJR",
      "X-TOWN",
      "YUP",
      "ZING",
      "ZX50"
  ],
  "LADA": [
      "21043",
      "2107",
      "AFALINA",
      "LAIKA",
      "NIVA",
      "SAMARA"
  ],
  "LANCER": [
      "LANCER"
  ],
  "LAND ROVER": [
      "110",
      "DEFENDER",
      "DISCOVERY",
      "FREELANDER",
      "LR3",
      "RANGE",
      "RANGER"
  ],
  "LEGNANO": [
      "CAPRI",
      "IMOLA",
      "MILANO",
      "MODENA",
      "MONZA",
      "VERONA",
      "YG110-2"
  ],
  "LEXUS": [
      "ES",
      "GS",
      "GS300",
      "IS",
      "LC",
      "LS",
      "LS-400",
      "LX",
      "NX",
      "RC",
      "RX",
      "UX"
  ],
  "LIFAN": [
      "1022",
      "FOISON",
      "KP200M",
      "KP200T",
      "KP350",
      "KPV150",
      "LF125",
      "LF150",
      "LF150T",
      "LF5021XXY",
      "M7",
      "MYWAY",
      "V16",
      "X50",
      "X60",
      "X70",
      "XP200"
  ],
  "LOTUS": [
      "ELISE",
      "EVORA",
      "EXIGE"
  ],
  "MACK": [
      "CT",
      "CV713",
      "GRANITE",
      "GU813",
      "RD688S",
      "RD688SX"
  ],
  "MARUTI": [
      "800",
      "ALTO"
  ],
  "MARUTI SUZUKI": [
      "800"
  ],
  "MASERATI": [
      "3200",
      "3500",
      "GHIBLI",
      "GRAN",
      "LEVANTE",
      "QUATROPORTE",
      "QUATTROPORTE",
      "SPYDER"
  ],
  "MAVERICK": [
      "BIG",
      "BLACK",
      "CROSS",
      "FOX",
      "GO",
      "HALLEY",
      "MA",
      "MA110-3",
      "MA110-A",
      "MA125-12",
      "MA125-2",
      "MA125-5",
      "MA125-7",
      "MA125-7A",
      "MA125-GY",
      "MA125-T6",
      "MA150-12",
      "MA150-13",
      "MA200-13",
      "MA200-GY",
      "MA250",
      "MA70",
      "MA70-D",
      "OMEGA",
      "PANTER",
      "SCAPE",
      "SPORT",
      "STREET",
      "TOP",
      "TRY",
      "XRT",
      "MOTORCYCLES"
  ],
  "MAXUS": [
      "DUNESTAR"
  ],
  "MAZDA": [
      "3 ",
      "323",
      "323F",
      "626",
      "929",
      "B 2200",
      "B 2600",
      "B2000/2200",
      "B2200",
      "B2500",
      "B2500D",
      "B2500DUG92",
      "B2900",
      "E2200/PICKUP",
      "MAZDA",
      "MIATA",
      "MPV",
      "MVP",
      "MX3",
      "MX5",
      "MX-5",
      "MX6",
      "PRIMACY",
      "PROTEGE",
      "PROTEGE/PRO",
      "RX7",
      "5 GT"
  ],
  "MCLAREN": [
      "570S"
  ],
  "MERCEDES BENZ": [
      "05",
      "1019",
      "1117",
      "1214",
      "1214/45",
      "1214/48",
      "1214C",
      "1215/48",
      "1215C",
      "1222",
      "1315",
      "1317",
      "1317/37",
      "1414",
      "1417",
      "1417C",
      "1418",
      "1419",
      "1518",
      "1614",
      "1618",
      "1620",
      "1622",
      "1623",
      "1624",
      "1625",
      "1625L",
      "1633",
      "1635",
      "1718",
      "1718A",
      "1718M",
      "1719",
      "1720",
      "1720A",
      "1720K",
      "1721S/32",
      "1722",
      "1722S/32",
      "1723",
      "1728",
      "1824",
      "1834",
      "1840",
      "1919",
      "1919K",
      "1923",
      "1928",
      "1933",
      "1935",
      "1935/1938/1941",
      "1935/1938/41",
      "1935AS",
      "1936",
      "1938",
      "1941",
      "1944S",
      "2038",
      "2222B",
      "2224",
      "2224B",
      "2225",
      "2235",
      "230C",
      "2318",
      "2318/2325",
      "2320",
      "2324",
      "2325",
      "2423",
      "2426",
      "2428",
      "2528",
      "2540",
      "2626",
      "2628",
      "2631",
      "2632K",
      "2636",
      "2638",
      "2640S",
      "2726",
      "290",
      "300",
      "313",
      "320",
      "3228",
      "3229",
      "3233",
      "3234",
      "3328I",
      "3535",
      "412",
      "412D",
      "413",
      "450",
      "500",
      "693",
      "695",
      "709",
      "709D/4250/3700",
      "710",
      "711D",
      "712C",
      "813",
      "912",
      "912/913",
      "912-42",
      "914",
      "914/42,5",
      "914C",
      "958",
      "A 170",
      "A 190",
      "A 200",
      "A140",
      "A150",
      "A160",
      "A170",
      "A200",
      "A250",
      "A45",
      "ACCELO",
      "ACTROS",
      "ACTROS2042",
      "AMG",
      "AROCS",
      "ATEGO",
      "ATRON",
      "AXOR",
      "B170",
      "B180",
      "B200",
      "BMO",
      "C 180",
      "C 200",
      "C 220",
      "C 230",
      "C 240",
      "C 250",
      "C 270",
      "C 280",
      "C 300",
      "C 320",
      "C180",
      "C200",
      "C220",
      "C230",
      "C250",
      "C280",
      "C300",
      "C320",
      "C320W",
      "C350",
      "C400",
      "C43",
      "C55",
      "C63",
      "CL",
      "CLA",
      "CLC",
      "CLK",
      "CLK200",
      "CLK350",
      "CLK430",
      "CLK500",
      "CLS",
      "DB",
      "E 200",
      "E 220",
      "E 240",
      "E 250",
      "E 270",
      "E 280",
      "E 290",
      "E 300",
      "E 320",
      "E 350",
      "E 430",
      "E 450",
      "E 500",
      "E220",
      "E250",
      "E300",
      "E320",
      "E400",
      "E50",
      "E500",
      "E55",
      "E63",
      "G500",
      "GL",
      "GLA",
      "GLB",
      "GLC",
      "GLE",
      "GLK",
      "GLK300",
      "GLS",
      "L 1114",
      "L 1214",
      "L 1215/42",
      "L 1215/48",
      "L 1217",
      "L 1218",
      "L 1218/48",
      "L 1517/48",
      "L 1619/45",
      "L 1620/18/17",
      "L 1620/45",
      "L 1620/51",
      "L 1622/51",
      "L 1623",
      "L 1633",
      "L 1941",
      "L 2221/54",
      "L 2325/51",
      "L 2638",
      "L 710",
      "L 710/37",
      "L 710/42",
      "L 710/712",
      "L 710D/37",
      "L 712",
      "L 911",
      "L 912",
      "L 912-42.5",
      "L 913",
      "L1017A",
      "L-1114",
      "L1214/42/48",
      "L1214/48",
      "L-1215",
      "L-1218",
      "L-1318",
      "L-1414",
      "L-1418",
      "L1418R",
      "L-1618/51",
      "L-1620",
      "L1620/51",
      "L1622",
      "L-1622",
      "L1622/51",
      "L-1624",
      "L1632",
      "L1633",
      "L1634",
      "L-1926",
      "L1938",
      "L-1938",
      "L-1938/1941",
      "L1938/56",
      "L2624",
      "L-2635",
      "L-712/42.5",
      "L-913D/42",
      "L914/42",
      "L914/42.5",
      "L914/42/42.5",
      "LA",
      "LA-1418/51",
      "LAK",
      "LB",
      "LF-1113B",
      "LK",
      "LK-1318",
      "LK1418R",
      "LK1617/39",
      "LK1620",
      "LK-2318",
      "LO",
      "LO-814",
      "LO-915",
      "LP",
      "LPK808",
      "LS",
      "LS1622",
      "LS-1622",
      "LS1622/45",
      "LS1633",
      "LS-1633",
      "LS-1634",
      "LS1938",
      "LS-1941",
      "LS-2635",
      "MB",
      "MB-160",
      "MERCEDES",
      "MERCEDES-AMG",
      "ML",
      "O 500",
      "O-400",
      "O-500",
      "OC1214",
      "OF",
      "OF1722",
      "S 300",
      "S 320",
      "S 400",
      "S 500",
      "S500",
      "S550",
      "S65",
      "SL",
      "SL-320",
      "SLK",
      "SLK200",
      "SLK250",
      "SLK350",
      "SLS",
      "SPORTCOUPE",
      "SPRINTER",
      "V 220",
      "V 230",
      "V230",
      "V250",
      "VIANO",
      "VITO",
      "OF-1724"
  ],
  "MERCEDES": [
      "BENZ/COMIL",
      "BENZ/MARCOPOLO"
  ],
  "METRO": [
      "MD"
  ],
  "MG": [
      "MGF",
      "MIDGET"
  ],
  "MINI": [
      "CLUBMAN",
      "COOPER",
      "COUNTRYMAN",
      "JOHN",
      "ONE"
  ],
  "MITSUBISHI": [
      "3000",
      "3000/GT",
      "ASX",
      "CANTER",
      "COLT",
      "ECLIPSE",
      "ECLIPSE/RS",
      "ENDEAVOR",
      "GALA./SAPPORO",
      "GALA.2000",
      "GALA.OTROS",
      "GALANT",
      "L 200",
      "L 300",
      "L200",
      "L300",
      "LANCER",
      "MONTERO",
      "MONTERO/LS/SR/GLS",
      "NATIVA",
      "NEW",
      "OUTLANDER",
      "PAJERO",
      "SPACE"
  ],
  "MONDIAL": [
      "BD",
      "CARGO",
      "CD200Y",
      "DAX",
      "DAX70",
      "EX150K",
      "FD110L",
      "FD110S",
      "FD125",
      "FD150",
      "FD150L",
      "FD150LN",
      "FD150Q",
      "FD150S",
      "FD150SV",
      "FD150Y",
      "FD200",
      "FD200-RS",
      "FD200S",
      "FD200ST",
      "FD250",
      "FD250H",
      "FD250Q",
      "FD250R",
      "FD250S",
      "FD250ST",
      "FD250X",
      "FD250Y",
      "FD250YR",
      "FD300LN",
      "FD300S",
      "FD50",
      "FD50A",
      "FD50S",
      "FREE",
      "HD",
      "HD125",
      "HD125L",
      "HD150L",
      "HD250A",
      "HD250W",
      "HD254A",
      "KX",
      "KX50",
      "LD",
      "LD110",
      "LD110H",
      "LD110K",
      "LD110L",
      "LD110Q",
      "LD110S",
      "LD110Y",
      "LD110YT",
      "LD125L",
      "MD",
      "MD125K",
      "MD125K1",
      "MD150",
      "MD150N",
      "MONKEY",
      "MS",
      "MS50D",
      "MS50E",
      "QJ100E",
      "QJ110E",
      "RD",
      "RD125",
      "RD125K",
      "RD150K",
      "RD150L",
      "RD200K",
      "RD250",
      "RD250R",
      "RD250S",
      "RV",
      "SCARABEO",
      "SILVER",
      "TB100",
      "TB125",
      "TD",
      "TD150L",
      "TD200K",
      "TD200K-2",
      "VD250P",
      "W150",
      "W250"
  ],
  "MOTO GUZZI": [
      "V7",
      "V85",
      "V9"
  ],
  "MOTOMEL": [
      "B110",
      "B125",
      "C 125",
      "C100",
      "C110",
      "C150",
      "C250",
      "CA",
      "CA100",
      "CA100DB",
      "CA110",
      "CD",
      "CD100",
      "CD125",
      "CG",
      "CITY",
      "CUSTOM",
      "CX",
      "CX150",
      "CX250",
      "DK",
      "DK200",
      "DREAM",
      "E110",
      "ECO",
      "FORZA",
      "GO",
      "GORILLA",
      "GT",
      "GV650",
      "HYOSUNG",
      "KRAKEN",
      "LYNX",
      "M 70",
      "M70",
      "MEDAL",
      "MEGELLI250",
      "MOTARD",
      "MX110",
      "MX250",
      "NF100",
      "PITBULL",
      "PX110",
      "QS250",
      "QUEST",
      "S 250",
      "S110",
      "S2",
      "S250",
      "S3",
      "S70",
      "SIRIUS",
      "SIRIUS200",
      "SKUA",
      "SKUA200",
      "SKUA250",
      "SL",
      "SL125",
      "SQ",
      "SQ50",
      "SR",
      "SR200",
      "STRATO",
      "SUNTRIKE",
      "T150",
      "T200",
      "TRACKER",
      "TRUCKER",
      "VOLKANO",
      "VX150",
      "X3M",
      "XMM"
  ],
  "MV AGUSTA": [
      "BRUTALE",
      "F3",
      "F4",
      "RIVALE",
      "STRADALE",
      "TURISMO"
  ],
  "NAKAI": [
      "CHANGAN",
      "DELIVERY",
      "SC1011",
      "YANTAI"
  ],
  "NISSAN": [
      "350Z",
      "370Z",
      "4X2",
      "4X4",
      "AD",
      "ALTIMA",
      "CABINA",
      "DOBLE",
      "ECO-T.100",
      "FRONTIER",
      "GT-R",
      "INTERSTAR",
      "JUKE",
      "KICKS",
      "L-80.095",
      "LEAF",
      "LUCINO",
      "MARCH",
      "MAXIMA",
      "MICRA",
      "MURANO",
      "NAVARA",
      "NISSAN",
      "NOTE",
      "NP300",
      "PATHFINDER",
      "PATROL",
      "PRIMASTAR",
      "PRIMERA",
      "QUEST",
      "QUEST/XE/GXE",
      "SENTRA",
      "SERENA",
      "SERENA/2.0",
      "SUNNY",
      "TEANA",
      "TERRANO",
      "TIIDA",
      "TITAN",
      "TRADE",
      "VERSA",
      "X TERRA",
      "XTERRA",
      "X-TERRA",
      "X-TRAIL",
      "ARMADA"
  ],
  "NISSAN DIESEL": [
      "CPC16"
  ],
  "NORTON": [
      "COMMANDO"
  ],
  "NUUV": [
      "M+",
      "N SPORT",
      "NGT",
      "U PRO"
  ],
  "OKINOI": [
      "150",
      "250",
      "DROP",
      "NAKED",
      "OKIMOTARD",
      "OKINOI",
      "OKN",
      "R 150",
      "R 250",
      "ST",
      "STREET",
      "SUPERMOTARD",
      "TUNING"
  ],
  "OLMO": [
      "FLASH",
      "XT"
  ],
  "OPEL": [
      "ASTRA",
      "COMBO",
      "CORSA",
      "MERIVA",
      "VECTRA",
      "VIVARO",
      "ZAFIRA"
  ],
  "PANTHER": [
      "110F",
      "110R",
      "70F",
      "90F",
      "WR110",
      "WR125",
      "WR150",
      "WR150T",
      "WR200",
      "WR250"
  ],
  "PANTHER QUADS": [
      "110",
      "200R",
      "70",
      "90",
      "ATV",
      "WR",
      "150"
  ],
  "PEUGEOT": [
      "1007",
      "106",
      "106S16",
      "107",
      "2008",
      "205",
      "206",
      "206XR",
      "206XRD",
      "206XRD3",
      "206XRD3PK",
      "206XRD3PKDAB",
      "206XRD5",
      "206XRD5PK",
      "206XRD5PKDAB",
      "206XRN3",
      "206XRN3PK",
      "206XRN3PKDAB",
      "206XRN5",
      "206XRN5PK",
      "206XRN5PKDAB",
      "206XS",
      "206XSN3DABLA",
      "206XTN5DAB",
      "206XTN5DABLA",
      "207",
      "208",
      "3008",
      "301",
      "306",
      "306BRLD4AA",
      "306BRLD5AA",
      "306BRLHDI4",
      "306BRLN4",
      "306BRLN4AA",
      "306BRLN5",
      "306BRLN5AA",
      "306EQHDI4ABS",
      "306EQHDI5ABS",
      "306EQN4ABS",
      "306EQN5ABS",
      "306EQTD4ABS",
      "306EQTD5ABS",
      "306RLEN3ABS",
      "306SOLHDI4",
      "306SOLN4",
      "306TMHDI5",
      "306TMN3",
      "306TMN5",
      "307",
      "307SW",
      "308",
      "308S",
      "4008",
      "405",
      "405STYLED",
      "405STYLEDAA",
      "405STYLEDPOL",
      "405STYLEN",
      "405STYLENAA",
      "406",
      "406ST",
      "407",
      "408",
      "5008",
      "504",
      "504/GD/G",
      "504D/SRDTCA/XSDTF/XSDTAX",
      "505",
      "508",
      "605",
      "607",
      "806",
      "806/ST",
      "806ST",
      "807",
      "AMB.",
      "BERLINA",
      "BOXER",
      "C3",
      "CABRIOLET",
      "D 504",
      "EXPERT",
      "GRAND",
      "HOGGAR",
      "J5",
      "LA",
      "PAR",
      "PARFUD6",
      "PARFUD6PL",
      "PARFUD6PLDA",
      "PARFUD6PLDAA",
      "PARFUD8PLDA",
      "PARFUD8PLDAA",
      "PARFUN6",
      "PARPAD2PLN1",
      "PARPAD2PLN2",
      "PARPAD2PLN3",
      "PARPADDAA",
      "PARPADDAADAB",
      "PARPAHDI2PLC",
      "PARPAN2PLN1",
      "PARPAN2PLN2",
      "PARPAN2PLN3",
      "PARPANDAA",
      "PARPANDAADAB",
      "PART",
      "PARTNER",
      "PUP",
      "P-UP",
      "RANCH",
      "RCZ",
      "TRAVELLER",
      "XSARA"
  ],
  "PIAGGIO": [
      "M68",
      "MP3",
      "VESPA"
  ],
  "PLYMOUTH": [
      "GRAND",
      "VOYAGER"
  ],
  "POLARIS": [
      "RANGER",
      "RZR",
      "SCRAMBLER",
      "SPORTSMAN"
  ],
  "PONTIAC": [
      "GRAND",
      "SOLSTICE"
  ],
  "PORSCHE": [
      "718",
      "911",
      "911/T/S",
      "911S",
      "911T",
      "987",
      "993",
      "BOXSTER",
      "C2",
      "CARRERA",
      "CAYENNE",
      "CAYMAN",
      "F-PACE",
      "GT3",
      "GT4",
      "MACAN",
      "PANAMERA",
      "R75",
      "TARGA",
      "TURBO"
  ],
  "PROTON": [
      "416",
      "420",
      "420D"
  ],
  "QINGQI": [
      "QS90-2"
  ],
  "RAM": [
      "RAM"
  ],
  "RANGE ROVER": [
      "4.6",
      "VOGUE"
  ],
  "RENAULT": [
      "19",
      "21",
      "25",
      "ALASKAN",
      "CAPTUR",
      "CBH",
      "CLIO",
      "CLIO2",
      "D 19",
      "D16",
      "DG230-20",
      "DG290.TL",
      "DUSTER",
      "EXPRESS",
      "FLUENCE",
      "GRAND",
      "KANGOO",
      "PROFESIONAL",
      "KERAX",
      "KOLEOS",
      "KWID",
      "LAGUNA",
      "LATITUDE",
      "LOGAN",
      "MAGNUM",
      "MASTER",
      "MEGANE",
      "MEGANEII",
      "MIDLUM",
      "MODUS",
      "MS",
      "MURANO",
      "NUEVA",
      "NUEVO",
      "OROCH",
      "PREMIUM",
      "R 19",
      "R19",
      "R-19",
      "R-20",
      "R-21",
      "R-21/GTX",
      "RODEO",
      "SANDERO",
      "SCENIC",
      "STEPWAY",
      "SYMBOL",
      "TIIDA",
      "TRAFIC",
      "TWINGO",
      "VEL"
  ],
  "ROVER": [
      "200",
      "214",
      "216",
      "218",
      "220",
      "414",
      "416",
      "420",
      "45",
      "4S",
      "618",
      "620",
      "623",
      "75",
      "820",
      "COUNTY",
      "E-RS25",
      "R 25",
      "R 45",
      "R 75",
      "R75",
      "RANGE",
      "ROVER"
  ],
  "ROYAL ENFIELD": [
      "BULLET",
      "CLASSIC",
      "CONTINENTAL",
      "HIMALAYAN",
      "INTERCEPTOR",
      "METEOR",
      "SCRAM"
  ],
  "RVM": [
      "250-9",
      "400",
      "CF650",
      "CF650TR",
      "CZ",
      "JAWA250",
      "RM600",
      "RVM",
      "TEKKEN",
      "TEKKEN-250"
  ],
  "SAAB": [
      "9.3",
      "9.5",
      "900",
      "9000",
      "9000/CD/CDE",
      "900S/SE/SE",
      "9-3",
      "AERO",
      "ARC"
  ],
  "SAAB SCANIA": [
      "P 114",
      "P 124",
      "P 94",
      "P93H",
      "P94",
      "R 114",
      "R 124",
      "R113",
      "T 114",
      "T 124",
      "T113H"
  ],
  "SANTANA": [
      "JX",
      "SAMURAI",
      "SJ-410",
      "VITARA"
  ],
  "SANXING": [
      "SXZ1045B",
      "SXZ1045BP",
      "SXZ1047",
      "SXZ1047W",
      "SXZ3043"
  ],
  "SATURN": [
      "VUE"
  ],
  "SCANIA": [
      "114C",
      "114G",
      "114L",
      "124C",
      "124G",
      "124L",
      "144L",
      "164L",
      "93",
      "93H",
      "94D",
      "94G",
      "G310",
      "G320",
      "G340",
      "G360",
      "G380",
      "G400",
      "G410",
      "G420",
      "G440",
      "G450",
      "G460",
      "G470",
      "G480",
      "G500",
      "K250",
      "K310",
      "K400",
      "K440",
      "P 380",
      "P094DB",
      "P114",
      "P114CA",
      "P114GB",
      "P114LA6X2",
      "P124",
      "P124CA",
      "P124CB",
      "P124GA",
      "P124GB",
      "P124LB",
      "P230",
      "P250",
      "P270",
      "P280",
      "P310",
      "P320",
      "P340",
      "P360",
      "P380",
      "P400",
      "P410",
      "P420",
      "P440",
      "P94",
      "P94CB",
      "P94DB",
      "R113",
      "R113E",
      "R113H",
      "R113M",
      "R113MA",
      "R114",
      "R114GA",
      "R114GB",
      "R124",
      "R124GA",
      "R124GB",
      "R124LBX2",
      "R142H",
      "R142HS",
      "R142MA",
      "R142ML",
      "R144",
      "R144LA4X2NA530",
      "R340",
      "R360",
      "R380",
      "R400",
      "R410",
      "R420",
      "R440",
      "R450",
      "R470",
      "R480",
      "R500",
      "R540",
      "R560",
      "R580",
      "R620",
      "S450",
      "S500",
      "S540",
      "S620",
      "T113E",
      "T113H",
      "T114GA",
      "T114GB",
      "T124GA",
      "T124GB",
      "T124LA",
      "R460"
  ],
  "SCANIA/MARCOPOL": [
      "O "
  ],
  "SEAT": [
      "ALHAMBRA",
      "ALTEA",
      "CORDOBA",
      "FURGON",
      "IBIZA",
      "INCA",
      "LEON",
      "TOLEDO"
  ],
  "SERO": [
      "ELECTRIC"
  ],
  "SHERCO": [
      "250",
      "300"
  ],
  "SHINERAY": [
      "G01",
      "T30",
      "T32",
      "X30",
      "X7"
  ],
  "SIAM": [
      "QU",
      "TRENDER"
  ],
  "SKODA": [
      "FELICIA"
  ],
  "SMART": [
      "FORFOUR",
      "FORTWO",
      "PASSION",
      "SMART"
  ],
  "SOUEAST": [
      "DX3"
  ],
  "SPACE": [
      "GHT1047",
      "GHT1055"
  ],
  "SSANGYONG": [
      "ACTYON",
      "CHAIRMAN",
      "ISTANA",
      "KORANDO",
      "KYRON",
      "MUSSO",
      "OM662",
      "REXTON",
      "RODIUS"
  ],
  "SUBARU": [
      "FORESTER",
      "IMPREZA",
      "LEGACY",
      "LEGACY/GX/GL/LS/LX/L",
      "OUTBACK",
      "TRIBECA",
      "WRX",
      "XV"
  ],
  "SUMO": [
      "CG125",
      "KCS2002F",
      "KCS2002G",
      "SB110",
      "SG125",
      "SL200"
  ],
  "SUNEQUIP": [
      "RANCHER"
  ],
  "SUNRA": [
      "GRACE",
      "HAWK",
      "LEO",
      "LMJR",
      "MIKU"
  ],
  "SUZUKI": [
      "ALTO",
      "AN",
      "AX",
      "AX100",
      "AX-100",
      "AX100A",
      "AX100V",
      "BALENO",
      "BURGMAN",
      "CARRY",
      "DL1000",
      "DL1000XA",
      "DL1050RC",
      "DL650",
      "DL650A",
      "DL650XA",
      "DR-Z400S",
      "EN125",
      "EN125-2A",
      "EN125-HU",
      "FUN",
      "G.",
      "GN",
      "GN125E",
      "GN125EN",
      "GN125ER",
      "GN125ES",
      "GN125ET",
      "GN125-F",
      "GN125H",
      "GRAND",
      "GS",
      "GSF650",
      "GSF650SA",
      "GSR",
      "GSR600",
      "GSX",
      "GSX1100F",
      "GSX150",
      "GSX600FS",
      "GSX-R",
      "GSXR",
      "GSX-R1000",
      "GSX-R1100",
      "GSX-R1100WS",
      "GSXR600",
      "GSX-R600",
      "GSX-R600V",
      "GSX-R600WN",
      "GSX-S750",
      "GSX-S750A",
      "GW250",
      "JIMNY",
      "KINGQUAD",
      "LT",
      "LT-A400FK7",
      "LT-A400K7",
      "LT-A750XZK8",
      "LT-F",
      "LT-F250",
      "LT-F250R",
      "LT-F400FK8",
      "LT-R450",
      "LT-Z400",
      "QUADSPORT",
      "RM",
      "RMZ250",
      "RM-Z250",
      "RM-Z450",
      "SAMURAI",
      "SAMURAI/413",
      "SANTANA",
      "SJ",
      "SJ110",
      "SJ110D",
      "SV650A",
      "SV650XA",
      "SWIFT",
      "SX4",
      "VITARA",
      "VITARA/JLX",
      "V-STROM",
      "VZ800",
      "VZ800W",
      "VZ800Y",
      "WAGON",
      "XL-7",
      "CELERIO"
  ],
  "SUZUKI - SANTANA": [
      "SAMURAI"
  ],
  "SYM": [
      "CITYCOM",
      "CROX",
      "FIDDLE",
      "JOYRIDE",
      "MAXSYM",
      "SYMPHONY",
      "T1"
  ],
  "TATA": [
      "207",
      "407",
      "608",
      "608/37",
      "609/38",
      "SAFARI",
      "SFC",
      "SUMO",
      "SUMO-EX",
      "TATAMOBILE",
      "TELCO",
      "TELCOLINE"
  ],
  "TIBO": [
      "AERO",
      "CAT",
      "CHARGER",
      "COASTER",
      "HUNTER",
      "JET",
      "NITRO",
      "RAIDER",
      "RALLY",
      "RIDER",
      "RS",
      "SAMURAI",
      "SHARK",
      "STORM",
      "TAZZ",
      "ZONDA"
  ],
  "TOYOTA": [
      "4 RUNNER",
      "4 RUNNER/V6/2.8",
      "4RN",
      "4WD",
      "AURIS",
      "AVALON",
      "AVENSIS",
      "CAMRY",
      "CAMRY/XLE/LE/DX",
      "C-HR",
      "COASTER",
      "COROLLA",
      "CORONA",
      "ETIOS",
      "EXTRACAB",
      "FJ",
      "FORTUNER",
      "GR",
      "HIACE",
      "HI-ACE",
      "HIGHLANDER",
      "HILUX",
      "INNOVA",
      "LAND",
      "LEXUS",
      "NEW",
      "PRIUS",
      "RAV",
      "RAV4",
      "RAV4J",
      "SIENNA",
      "SR",
      "SR5",
      "SUPRA",
      "SW4",
      "TACOMA",
      "TUNDRA",
      "YARIS",
      "86"
  ],
  "TRIUMPH": [
      "BONNEVILLE",
      "EXPLORER",
      "ROCKET",
      "SCRAMBLER",
      "SPEED",
      "STREET",
      "THRUXTON",
      "TIGER"
  ],
  "TVS": [
      "NTORQ",
      "RTR"
  ],
  "VOGE": [
      "300",
      "500DS",
      "500R",
      "650DS"
  ],
  "VOLKSWAGEN": [
      "10.150",
      "13.180",
      "13.180E",
      "13180",
      "14150",
      "15.180",
      "15.180E",
      "15.190",
      "15160",
      "15170",
      "15180",
      "17.190",
      "17.190DC",
      "17.210",
      "17.230",
      "17.250",
      "17.280",
      "17160",
      "17210",
      "17220",
      "17300",
      "17310",
      "17-310",
      "18.280",
      "18.310",
      "18.330",
      "18310",
      "19.320",
      "19.330",
      "19.360",
      "19.390",
      "19.420",
      "25.320",
      "25.420",
      "26.260",
      "26.280",
      "26.420",
      "26260",
      "26300",
      "26310",
      "31.280",
      "31.320",
      "31.330",
      "5.140",
      "7100",
      "8.150",
      "9.150",
      "9.160",
      "9140",
      "9150",
      "AMAROK",
      "BEETLE",
      "BLACK",
      "BORA",
      "CADDY",
      "CARAVELLE",
      "CONSTELLATION",
      "CROSS",
      "CROSSFOX",
      "DELIVERY",
      "DERBY",
      "FOX",
      "FUSCA",
      "GOL",
      "GOL/GL/GLI",
      "GOLF",
      "HIGH",
      "JETTA",
      "MOVE",
      "MULTIVAN",
      "NEW",
      "NIVUS",
      "NUEVO",
      "PARA",
      "PARATI",
      "PASSAT",
      "PASSAT/GL/VR6/TDI",
      "PEPPER",
      "PHAETON",
      "POLO",
      "QUANTUM",
      "RABBIT",
      "ROUTAN",
      "SANTANA",
      "SAVEIRO",
      "SCIROCCO",
      "SHARAN",
      "SURAN",
      "TAKE",
      "TAOS",
      "T-CROSS",
      "THE",
      "TIGUAN",
      "TOUAREG",
      "TOURAN",
      "TRANSPORTER",
      "VENTO",
      "VIRTUS",
      "VOLKSWAGEN",
      "VOYAGE",
      "WHITE",
      "13.190"
  ],
  "VOLVO": [
      "B 290",
      "B380R",
      "B450R",
      "C30",
      "C70",
      "FH",
      "FH12",
      "FH12-380/4600",
      "FH12-420/3700-",
      "FH400",
      "FH440",
      "FH520",
      "FM",
      "FM12",
      "FM370",
      "FM9",
      "NH12",
      "NH12-380/4300",
      "NH12-380/5600-4X2R",
      "NH12-420/5600",
      "NL",
      "NL10-320/4200",
      "NL10-320/4250",
      "NL10-320/4600",
      "NL10-320/5400",
      "NL10-320/5800",
      "NL-12",
      "NL12-360",
      "NL12-360/4200",
      "NL12-360/4250",
      "NL12-360/4600",
      "NL12-360/5400",
      "NL12-410/4200",
      "NL12-410/4250",
      "NL12-410/4600",
      "NL12-410/5400",
      "S40",
      "S40TD",
      "S60",
      "S70",
      "S80",
      "S90",
      "V40",
      "V40TD",
      "V50",
      "V60",
      "V70",
      "VM",
      "VM17",
      "VM210",
      "VM23",
      "VM260",
      "VM310",
      "VM330",
      "VNL",
      "VNL660",
      "XC",
      "XC40",
      "XC60",
      "XC70",
      "XC90",
      "B270"
  ],
  "WULING": [
      "LZW1010PL",
      "LZW1010VHW",
      "LZW6320H"
  ],
  "YAMAHA": [
      "900",
      "BLASTER",
      "CRYPTON",
      "CYGNUS",
      "FASCINO",
      "FAZER",
      "FJR",
      "FJR1300A",
      "FJR1300AS",
      "FZ",
      "FZ1",
      "FZ16",
      "FZ1N",
      "FZ1S",
      "FZ25",
      "FZ6",
      "FZ6-N",
      "FZ6NHG",
      "FZ6-S",
      "FZ6SAHG",
      "FZ6SHG",
      "FZ8",
      "FZ8-N",
      "FZ8-S",
      "FZ-S",
      "GRIZZLY",
      "MT",
      "MT01",
      "MT03",
      "MT-07",
      "MT-07ST",
      "MT-09ST",
      "NMAX",
      "NM-X",
      "PW50",
      "R15",
      "R6",
      "RAPTOR",
      "RAY",
      "ROAD",
      "ROYAL",
      "SUPER",
      "SZ15RR",
      "T105",
      "T105E",
      "T110",
      "T110C",
      "TDM900",
      "TDM900A",
      "TENERE",
      "TTR",
      "TTR125",
      "TTR125E",
      "TTR125LW",
      "TTR125LWE",
      "TTR230",
      "TTR230T",
      "TTR230W",
      "TZ125",
      "TZR",
      "V STAR",
      "V-MAX",
      "WARRIOR",
      "WR250F",
      "WR250R",
      "WR250X",
      "WR450F",
      "XJ6-F",
      "XJ6-N",
      "XJ6-S",
      "XJR",
      "XP",
      "XSR900",
      "XT",
      "XT225",
      "XT225D",
      "XT600A",
      "XT600E",
      "XT600ED",
      "XT660R",
      "XT660X",
      "XT660Z",
      "XTZ",
      "XTZ125E",
      "XTZ125K",
      "XTZ150",
      "XTZ250",
      "XTZ250ABS",
      "XV1900A",
      "XV950",
      "XV950R",
      "XVA1100",
      "XVS",
      "XVS11",
      "XVS1100",
      "XVS1100A",
      "XVS1300A",
      "XVS65",
      "XVS650",
      "XVS650A",
      "XVS950A",
      "XVS950CUD",
      "XVZ1300AT",
      "YBR",
      "YBR125",
      "YBR125E",
      "YBR125ED",
      "YBR125K",
      "YBR250",
      "YBRZ",
      "YF",
      "YFM",
      "YFM125A",
      "YFM125GBL",
      "YFM125RAW",
      "YFM250",
      "YFM250R",
      "YFM25BYB",
      "YFM25BYGR",
      "YFM25RAL",
      "YFM25RAW",
      "YFM25RSP2X",
      "YFM25RSPA",
      "YFM25RXGY",
      "YFM25RXL",
      "YFM25RZW",
      "YFM25XLE",
      "YFM25XLGR",
      "YFM25XNE",
      "YFM300",
      "YFM350",
      "YFM350A",
      "YFM350AS",
      "YFM350ER",
      "YFM350FW",
      "YFM350FWA",
      "YFM350FWB",
      "YFM350FWHC",
      "YFM350FWX",
      "YFM350FXK",
      "YFM350GW",
      "YFM350R",
      "YFM350U",
      "YFM350X",
      "YFM350XE",
      "YFM350XG",
      "YFM350XH",
      "YFM350XJL",
      "YFM350XJY",
      "YFM350XKGY",
      "YFM350XKR",
      "YFM35FGAGR",
      "YFM35FGAL",
      "YFM35FGDGR",
      "YFM35FGHX",
      "YFM35FGIAG",
      "YFM35FGIHY",
      "YFM35FGIWR",
      "YFM35FGIYB",
      "YFM35FGXL",
      "YFM35FGYL",
      "YFM35GAL",
      "YFM35GXGR",
      "YFM35GYGR",
      "YFM35RAL",
      "YFM35RDW",
      "YFM35RLX",
      "YFM35RSE2W",
      "YFM35RWL",
      "YFM35RXGY",
      "YFM35RXL",
      "YFM35RYW",
      "YFM35RZW",
      "YFM35XHGR",
      "YFM35XXGY",
      "YFM35XYL",
      "YFM400FWAN",
      "YFM450FWA",
      "YFM450FWAN",
      "YFM550F",
      "YFM550FWA",
      "YFM5FGYL",
      "YFM660",
      "YFM660FP",
      "YFM660FWA",
      "YFM660R",
      "YFM660RN",
      "YFM700",
      "YFM700FWAD",
      "YFM700R",
      "YFM700RSEL",
      "YFM700RSES",
      "YFM70RSEY",
      "YFM70RSPX",
      "YFM70RXGY",
      "YFM70RXL",
      "YFM7FGBL",
      "YFM7FGHA",
      "YFM7FGHY",
      "YFM7FGHZ",
      "YFM7FGPAGR",
      "YFM7FGPAL",
      "YFM7FGPBGR",
      "YFM7FGPHA",
      "YFM7FGPHB",
      "YFM7FGPHY",
      "YFM7FGPSED",
      "YFM7FGPSEY",
      "YFM7FGPSPA",
      "YFM7FGPSPX",
      "YFM7FGPYB",
      "YFM7FGXL",
      "YFM7FGYB",
      "YFM7FGYL",
      "YFM7FGZL",
      "YFM7RAL",
      "YFM7RAW",
      "YFM7RDW",
      "YFM7RSE",
      "YFM7RSEA",
      "YFM7RSED",
      "YFM7RSEDB",
      "YFM7RSEZ",
      "YFM80",
      "YFM-80",
      "YFM80F",
      "YFM80G",
      "YFM80GHL",
      "YFM80GHX",
      "YFM80GWL",
      "YFM80GXGR",
      "YFM80H",
      "YFM80-J",
      "YFM80R",
      "YFM80RVL",
      "YFM80RVW",
      "YFM80RXL",
      "YFM90R",
      "YFS",
      "YFS200",
      "YFS-200",
      "YFS200F-GR",
      "YFS200G-L",
      "YFS200GN",
      "YFS200-GR",
      "YFS200H",
      "YFS200H-B",
      "YFS200H-L",
      "YFS200J-R",
      "YFS200J-Y",
      "YFS200K",
      "YFS200K-L",
      "YFS200K-R",
      "YFZ",
      "YFZ350",
      "YFZ350G",
      "YFZ350H",
      "YFZ350H-R",
      "YFZ350H-W",
      "YFZ350J-B",
      "YFZ350J-R",
      "YFZ450",
      "YFZ450R",
      "YFZ450RAL",
      "YFZ450RSEY",
      "YFZ450RSEZ",
      "YFZ450RYL",
      "YFZ450RYW",
      "YFZ450RZL",
      "YFZ450SL",
      "YFZ450SP2X",
      "YFZ450SPV",
      "YFZ450SPX",
      "YFZ450VL",
      "YFZ450WGY",
      "YFZ450WL",
      "YFZ450X",
      "YFZ450XBBZ",
      "YFZ450XGY",
      "YFZ450XL",
      "YFZ450XZL",
      "YFZ450XZW",
      "YFZ450YL",
      "YFZ450YW",
      "YFZ45BB",
      "YFZ45BW",
      "YFZ45FGYB",
      "YFZ45RBL",
      "YFZ45RBW",
      "YFZ45RSED",
      "YP400",
      "YS250",
      "YXR700F",
      "YZ",
      "YZ125F1",
      "YZ250",
      "YZ250F",
      "YZ250FX",
      "YZ250X",
      "YZ426F",
      "YZ450F",
      "YZ450FX",
      "YZ85",
      "YZ85LW",
      "YZF",
      "YZF250R",
      "YZF-R1",
      "YZF-R6",
      "YFM35FGBGR",
      "YFM35FGBL",
      "YFM35FGHB",
      "YFM35FGIAL",
      "YFM35XVL",
      "YFM45FGAL",
      "YFM45FGHB",
      "YFM5FGHA",
      "YFM7FGPSEZ",
      "YFM7RSEB",
      "YFZ450XAL",
      "YFZ45BWE",
      "YFZ45RDL",
      "YFZ45RDW",
      "YFZ45RSEB"
  ],
  "YUEJIN": [
      "HQ1608",
      "NJ",
      "NJ1026",
      "NJ1030DA",
      "NJ1030DAS",
      "NJ1035DER",
      "NJ1041",
      "NJ1061",
      "NJ1062",
      "NJ1062DC",
      "NJ1062DCW",
      "NJ1062DEW",
      "NJ3061",
      "NJ3062",
      "NJ3062DE",
      "NJ6604D",
      "NJ6700D"
  ],
  "ZANELLA": [
      "CECCATO",
      "CICL.",
      "CICL.SOL",
      "CICLOMOTOR",
      "CRUISER",
      "CUATRICICLO",
      "CUSTOM",
      "DELIBERY",
      "DELIVERY",
      "DIRTBIKE",
      "DUE",
      "E STYLER",
      "EXPLORER",
      "FX",
      "FX110",
      "FX125",
      "FX150",
      "FX200",
      "FX250",
      "FX300",
      "FX400",
      "FX50",
      "GFORCE",
      "GFORCE250",
      "GFORCE500",
      "GT2I",
      "HJ",
      "HJ110",
      "HJ125-7",
      "HOT",
      "MADASS",
      "MOD",
      "MOTO",
      "MOTONETA",
      "NEW",
      "PATAGONIA",
      "PATAGONIAN",
      "POCKET",
      "RALLY",
      "RAPTOR",
      "RK6",
      "RKS",
      "RKV",
      "RX",
      "RX150",
      "RX200",
      "RX250",
      "RZ",
      "RZ3",
      "RZ7",
      "SAPUCAI",
      "SEXY",
      "SOL",
      "SPEEDCRUISER",
      "SPEEDLIGHT",
      "STYLER",
      "SWING",
      "TRAKTOR",
      "TRICARGO",
      "TX",
      "VENTO",
      "Z MAX",
      "Z TRUCK",
      "ZANELLA",
      "ZB",
      "ZB110",
      "ZB110D",
      "ZB125",
      "Z-CAP",
      "Z-CARGA",
      "ZCARGA",
      "ZCT",
      "ZCT110L",
      "ZCT125",
      "ZCT150",
      "ZR",
      "ZSC125",
      "ZTT",
      "ZTT200",
      "ZTT250"
  ]
}
