import { IService } from "../../interfaces";

export const services: IService[] = [
    {
        title: "Autolavado",
        icon: "MdMotionPhotosAuto",
        description: "Contamos con terminales de autolavado, donde puedes realizar la limpieza de tu vehículo o el de tu empresa, como lo harías en casa. Optimizamos el uso del agua, utilizamos productos amigables con el medio ambiente y ofrecemos instalaciones aptas para esta tareas.",
    },
    {
        title: "Manual",
        icon: "MdOutlineLocalCarWash",
        description: "El servicio de lavado manual para vehículos particulares y empresa se centra en cuidar la estética de los automóviles con un enfoque personalizado y ecológico. Utiliza productos amigables con el medio ambiente y aplica prácticas responsables para el manejo de residuos. Nuestro equipo no solo limpia, sino que también protege y embellece los vehículos, promoviendo un enfoque sostenible y manteniéndolos en óptimas condiciones.",
    },
    {
        title: "Tapizados",
        icon: "GiCarSeat",
        description: "La limpieza de tapizados automovilísticos es un proceso esencial para mantener la estética y el confort del interior de los vehículos. Este servicio incluye la eliminación de manchas, suciedad y olores de diferentes tipos de materiales, como tela, cuero o vinilo. Utilizamos técnicas especializadas y productos diseñados para cada tipo de tapizado, asegurando una limpieza profunda sin dañar las superficies.",
    },
    {
        title: "Motor - Chasis",
        icon: "SiGodotengine",
        description: "El proceso de limpieza de motores y chasis consiste en eliminar suciedad, grasa y residuos acumulados en ambas áreas, utilizando productos específicos y técnicas adecuadas para proteger los componentes sensibles. La limpieza del motor incluye el desengrase de partes clave, facilitando la detección de fugas y problemas mecánicos, mientras que la limpieza del chasis se enfoca en eliminar barro, sal y contaminantes que pueden causar corrosión y dañar la estructura del vehículo.",
    }
];