import React from "react";
import styles from "./footer.module.css";
import { BsInstagram, BsFacebook, BsWhatsapp } from "react-icons/bs";
import maps_img from "../../img/maps.jpg";

export default function Footer() {
  return (
    <div className={styles.bottomPrimary}>
      <div className={styles.left}>
        <div className={styles.address}>
          <span>Lanín 97 Centenario </span>
          <span>CP. 8309, Neuquén</span>
          <span>+542995974319</span>
        </div>
        <div className={styles.social}>
          <a
            href="https://www.instagram.com/lanin_carwash/?hl=es"
            target="_blank"
            rel="noreferrer"
          >
            <BsInstagram
              className={styles.iconBottom}
              style={{ fontSize: "0.6rem" }}
            />
          </a>
          {/* <a
            href="https://www.facebook.com/CarWashLanin/?locale=es_LA"
            target="_blank"
            rel="noreferrer"
          >
            <BsFacebook
              className={styles.iconBottom}
              style={{ fontSize: "0.6rem" }}
            />
          </a> */}
          <a href="https://wa.me/542995974319" target="_blank" rel="noreferrer">
            <BsWhatsapp
              className={styles.iconBottom}
              style={{ fontSize: "0.6rem" }}
            />
          </a>
        </div>
        <div className={styles.copyr}>
          <span>LANIN CARWASH ©2023</span>
          <a href="https://www.cristianwenz.tech" target="_blank">
            <span>HARD-CODE TECHNOLOGIES®</span>
          </a>
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.map}>
          {/* <iframe
            width="100%"
            height="90%"
            src="https://www.openstreetmap.org/export/embed.html?bbox=-68.13066244125368%2C-38.83900564491327%2C-68.11422586441041%2C-38.829545106505996&amp;layer=mapnik&amp;marker=-38.834275532897216%2C-68.12244415283203"
          ></iframe> */}
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1168.8530725042656!2d-68.12178912932025!3d-38.83451561330987!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x960a4b80e8e91e49%3A0xb1c4abd26d8ba074!2sLan%C3%ADn%20Car%20Wash!5e1!3m2!1ses!2sar!4v1731101853885!5m2!1ses!2sar"
            width="100%"
            height="90%"
            style={{ border: 0 }}
            loading="lazy"
          ></iframe>
          <br />
        </div>
      </div>
    </div>
  );
}
