import { useEffect, useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { IconContext } from "react-icons";
import styles from "./navbar.module.css";
import { NavLink } from "react-router-dom";
import img from "../../img/carwash.png";
import { color_primary } from "../default";

const items = [
  { path: "/", name: "Inicio" },
  { path: "/us", name: "Nosotros" },
  { path: "/services", name: "Servicios" },
  { path: "/turn", name: "Turnos" },
  { path: "/contact", name: "Contacto" },
];

const Navbar = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [selected, setSelected] = useState("Inicio");
  useEffect(() => {
    let element = document.getElementById(selected);
    if (element) {
      element.style.backgroundColor = color_primary;
    }
  }, [selected]);

  const handleClick = (e: any, name: string) => {
    let element = document.getElementById(selected);
    if (element) {
      element.style.backgroundColor = "rgb(40, 37, 37)";
    }
    setSelected(name);
    mobileMenu(e);
  };

  const mobileMenu = async (e: any) => {
    e.preventDefault();
    let element = document.getElementById("mobileMenu");
    if (element?.style.left === "0px") {
      element.style.left = "-115%";
      setShowMobileMenu(!showMobileMenu);
      return;
    }
    if (element?.style.left === "-115%") {
      element.style.left = "0px";
      setShowMobileMenu(!showMobileMenu);
      return;
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <IconContext.Provider value={{ style: { fontSize: "2em" } }}>
          <div className={styles.TOP}>
            <div className={styles.logoContainer}>
              <NavLink to={"/"} style={{height:'100%'}}><img src={img} alt="Logo" className={styles.logoImg}/></NavLink>
            </div>
            <div className={styles.mobileIcon} onClick={(e) => mobileMenu(e)}>
              {showMobileMenu ? <FaTimes /> : <FaBars />}
            </div>
          </div>
          <ul id="mobileMenu" style={{ left: "-115%" }}>
            {items.map((item, index) => {
              return (
                <li key={index} onClick={(e) => handleClick(e, item.name)}>
                  <NavLink
                    id={item.name}
                    to={`${item.path}`}
                    className={styles.linkTo}
                  >
                    <div className={styles.div}>{item.name}</div>
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </IconContext.Provider>
      </div>
    </div>
  );
};

export default Navbar;
