import styles from "./contact.module.css";

export default function Contact() {
  return (
    <div id="CONTACTO" className={styles.Contact}>
      <div className={styles.WRAPPER}>
        <div className={styles.LEFT}>
          <h1>COMO ENCONTRARNOS</h1>
          <hr />
          <p />
          <span>
            Ubicados en Barrio Parque, Calle Lanin 97, entre calles Antártida
            Argentina y Rio Neuquén.
          </span>
          <span>
            Casco Viejo, Centenario, Provincia Neuquén, Patagonia Argentina.
          </span>
        </div>
        <div className={styles.RIGHT}>
          <h1>CONTACTO</h1>
          <hr />
          <p />
          <form action="https://formsubmit.co/cdwenz@gmail.com" method="POST">
            <input
              type="text"
              className={styles.input}
              name="name"
              placeholder="Nombre"
              required
            />

            <input
              type="email"
              className={styles.input}
              name="email"
              placeholder="email"
              required
            />

            <textarea
              name="text"
              className={styles.input}
              placeholder="Mensaje"
              required
            />

            <button type="submit" className={styles.inputBtn}>
              ENVIAR
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
