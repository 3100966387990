import { services } from "./const_services";
import styles from "./services.module.css";
import {
  MdMotionPhotosAuto,
  MdOutlineLocalCarWash,
} from "react-icons/md";
import { AiFillInfoCircle } from "react-icons/ai";
import { GiCarSeat } from "react-icons/gi";
import { SiGodotengine } from "react-icons/si";
import { NavLink } from "react-router-dom";
import BtnDefault from "../button";

function Services() {
  const getIcon = (icono: string) => {
    let icon = <AiFillInfoCircle />;
    switch (icono) {
      case "MdMotionPhotosAuto":
        icon = <MdMotionPhotosAuto className={styles.ICON} />;
        break;
      case "MdOutlineLocalCarWash":
        icon = <MdOutlineLocalCarWash className={styles.ICON} />;
        break;
      case "GiCarSeat":
        icon = <GiCarSeat className={styles.ICON} />;
        break;
      case "SiGodotengine":
        icon = <SiGodotengine className={styles.ICON} />;
        break;
    }

    return icon;
  };
  return (
    <>
      <div className={styles.PANEL}>
        {services.map((service, index) => {
          return (
            <figure key={index} className={styles.effect_ming}>
              <figcaption>
                <div>
                  {getIcon(service.icon)}
                  <h2>{service.title}</h2>
                </div>
                <p>{service.description}</p>
              </figcaption>
              <NavLink to={"/turn"}>
                <BtnDefault />
              </NavLink>
            </figure>
          );
        })}
      </div>
    </>
  );
}

export default Services;
