import styles from "./appoinments.module.css";

const hoursApp = [
  { time: "Morning", hours: ["08:30hs", "09:30hs", "10:30hs", "11:30hs"] },
  {
    time: "Afternoon",
    hours: ["12:30hs", "13:30hs", "14:30hs", "15:30hs", "16:30hs", "17:30hs"],
  },
];

function mesANumero(mesNombre: string) {
  // Creamos una fecha temporal en el primer día de cada mes
  for (let i = 0; i < 12; i++) {
    const fecha = new Date(2023, i, 1); // Año arbitrario, día 1 de cada mes
    const nombreMes = fecha.toLocaleDateString("es-ES", { month: "long" });

    if (nombreMes.toLowerCase() === mesNombre.toLowerCase()) {
      return i + 1; // Los meses en Date comienzan en 0 (enero), así que sumamos 1
    }
  }
  return 13; // Retorna null si el nombre del mes no coincide
}

function Appoinments(props: {
  dia: string | null;
  selected: string | null;
  setSelected: Function;
  diaEnFoco: number;
  mesEnFoco: string;
}) {
  const { dia, selected, setSelected, diaEnFoco, mesEnFoco } = props;

  // Obtener la hora actual
  const fechaActual = new Date();
  const horasActuales = fechaActual.getHours();
  const minutosActuales = fechaActual.getMinutes();

  // Función para verificar si un turno ya pasó
  const esTurnoPasado = (hora: string) => {
    const [horasTurno, minutosTurno] = hora
      .replace("hs", "")
      .split(":")
      .map(Number);
    if (new Date().getMonth() + 1 < mesANumero(mesEnFoco)) return false;
    if (
      new Date().getMonth() + 1 === mesANumero(mesEnFoco) &&
      new Date().getDate() < diaEnFoco
    )
      return false;
    if (horasTurno < horasActuales) return true;
    if (horasTurno === horasActuales && minutosTurno <= minutosActuales)
      return true;
    return false;
  };

  return (
    <div className={styles.mainApp}>
      {hoursApp.map((item, index) => {
        if (dia === "sáb" && item.time === "Afternoon") return;
        return (
          <div key={index} className={styles.containerBand}>
            <span>{item.time}</span>
            <hr />
            <div className={styles.containerFreeApp}>
              {item.hours.map((hour, i) => {
                const turnoPasado = esTurnoPasado(hour);
                return (
                  <>
                    <div
                      key={index + i}
                      className={`${
                        turnoPasado
                          ? styles.pastApp
                          : selected === hour
                          ? styles.selectedApp
                          : styles.freeApp
                      }`}
                      onClick={() => setSelected(hour)}
                    >
                      {hour}
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default Appoinments;
