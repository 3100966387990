import { useEffect, useState } from "react";
import styles from "./turn.module.css";
import { format } from "date-fns";
import axios from "axios";
import { color_primary } from "../../components/default/index";
import toast, { Toaster } from "react-hot-toast";
import Agenda from "../../components/agenda";

function validate(input: any) {
  const lengthPhone = input.phone?.toLocaleString().length;
  let error: any = {};
  if (!input.patent || input.patent.length < 6 || input.patent.length > 7)
    error.patent = "Patent is required";
  if (
    !input.phone ||
    lengthPhone === undefined ||
    lengthPhone < 9 ||
    lengthPhone > 12
  )
    error.phone = "Phone is required whithout '0' or '15'";
  if (!input.email) error.email = "Email is required";
  console.log("VALIDATE", error);
  return error;
}

function Turn() {
  const [state, setState] = useState(format(Date.now(), "PP"));

  console.log("DATE NOW: ", state);

  const object = {
    patent: "",
    phone: null,
    email: "",
    hour: "",
    date: format(Date.now(), "PP"),
  };
  const err: any = { name: "initial" };
  const [list, setList] = useState(object);
  const [errors, setErrors] = useState(err);
  const [hour, setHour] = useState<string>("");

  const hourNumber = (hour: string) => {
    const hourNum =
      hour.length === 5 ? Number(hour.slice(0, 2)) : Number(hour.slice(0, 1));
    return hourNum;
  };

  useEffect(() => {
    setList({
      ...list,
      hour,
    });
  }, [list, hour]);

  function handleChange(e: any) {
    setList({
      ...list,
      hour,
      [e.target.name]: e.target.value,
    });
    setErrors(
      validate({
        ...list,
        hour,
        [e.target.name]: e.target.value,
      })
    );
  }

  const notify = (message: string, icon: string) =>
    toast(message, {
      icon: icon,
      style: {
        borderRadius: "10px",
        background: "#333",
        color: "#fff",
      },
    });

  const handleClick = async (e: any) => {
    e.preventDefault();
    if (errors.patent || errors.phone || errors.email) {
      console.log({ errors });
    } else {
      let newHour = hourNumber(hour);
      const newList = {
        ...list,
        hour: newHour,
        date: state,
      };
      await axios
        .post(
          `${process.env.REACT_APP_DB_HOST}/turns`,
          // `http://localhost:3001/turns`,
          newList
        )
        .then((res) => {
          notify(res.data.message, "👏");
          setList(object);
          setHour("");
          setState(format(Date.now(), "PP"));
        })
        .catch((res) => notify(res.response.data, "🔥"));
    }
  };

  return (
    <div className={styles.CONTAINER}>
      <div className={styles.BANNER}>RESERVA TU TURNO</div>
      <form
        action=""
        className={styles.PANEL}
        onChange={(e) => handleChange(e)}
      >
        <div className={styles.DATE}>
          <div className={styles.CALENDAR}>
            <Agenda />
          </div>
        </div>
        <div className={styles.RESUME}>
          <h3 className={styles.TITLE}>DATOS</h3>
          <input
            className={styles.input}
            style={{ borderColor: errors.patent ? "red" : "gray" }}
            type="text"
            name="patent"
            placeholder={"Patente"}
            value={list.patent}
          />
          <input
            className={styles.input}
            style={{ borderColor: errors.phone ? "red" : "gray" }}
            type="number"
            name="phone"
            placeholder={"Telefono Movil sin '0' _ '15'"}
            value={list.phone || ""}
          />
          <input
            className={styles.input}
            style={{ borderColor: errors.email ? "red" : "gray" }}
            type="email"
            name="email"
            placeholder={"Email"}
            value={list.email}
          />
          <input
            className={styles.inputSubmit}
            type="submit"
            value={"Solicitar"}
            style={{
              backgroundColor: color_primary,
            }}
            onClick={(e) => handleClick(e)}
          />
        </div>
      </form>
      <Toaster />
    </div>
  );
}

export default Turn;
