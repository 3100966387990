import { useEffect } from "react";
import Footer from "./components/footer";
import Navbar from "./components/navbar";
import Home from "./pages/home";
import { Route, Routes } from 'react-router-dom';
import Us from "./pages/us";
import Gallery from "./pages/gallery";
import Turn from "./pages/turn";
import Contact from "./pages/contact";
import Services from "./pages/services";
import axios from "axios";

function App(): JSX.Element {
  useEffect(()=>{
    axios.get(`${process.env.REACT_APP_DB_HOST}/turns`)
  },[])

  return (
  <>
    <Navbar/>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/us" element={<Us />} />
      <Route path="/services" element={<Services />} />
      <Route path="/gallery" element={<Gallery />} />
      <Route path="/turn" element={<Turn />} />
      <Route path="/contact" element={<Contact />} />
    </Routes>
    <Footer />
  </>
  )
}

export default App;
