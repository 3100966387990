import { useEffect, useState } from "react";
import CalendarInLine from "./calendarInLine";
import Appoinments from "./appoinments";

interface Dia {
  dia: string;
  numero: number;
  mes: string;
  mesNum: number;
  anio: number;
}

function Agenda() {
  const [fechas, setFechas] = useState<Dia[]>([]);
  const [mesEnFoco, setMesEnFoco] = useState<string>("");
  const [diaEnFoco, setDiaEnFoco] = useState<number>(new Date().getDate());
  const [selected, setSelected] = useState<string | null>(null);
  const [diaName, setDiaName] = useState<string | null>(new Date().toLocaleDateString("es-ES", { weekday: "short" }));

  useEffect(() => {
    const generarFechasProximos30Dias = () => {
      const hoy = new Date();
      const fechasGeneradas: Dia[] = [];

      // Calcular el domingo anterior o igual a la fecha actual
      const primerDia = new Date(hoy);
      primerDia.setDate(hoy.getDate() - hoy.getDay()); // Ajusta al último domingo

      for (let i = 0; i < 30; i++) {
        const fecha = new Date(primerDia);
        fecha.setDate(primerDia.getDate() + i);

        const dia = fecha.toLocaleDateString("es-ES", { weekday: "short" });
        const numero = fecha.getDate();
        const mes = fecha.toLocaleDateString("es-ES", { month: "long" });
        const mesNum = fecha.getMonth() + 1;
        const anio = fecha.getFullYear();

        fechasGeneradas.push({ dia, numero, mes, mesNum, anio });
      }

      setFechas(fechasGeneradas);
      setMesEnFoco(fechasGeneradas[0].mes);
    };

    generarFechasProximos30Dias();
  }, []);

  const seleccionarDia = (dia: Dia) => {
    setMesEnFoco(dia.mes);
    setDiaEnFoco(dia.numero);
    setDiaName(dia.dia);
    setSelected(null);
  };

  return (
    <>
      <CalendarInLine
        fechas={fechas}
        diaEnFoco={diaEnFoco}
        mesEnFoco={mesEnFoco}
        seleccionarDia={seleccionarDia}
      />
      <Appoinments
        dia={diaName}
        selected={selected}
        setSelected={setSelected}
        diaEnFoco={diaEnFoco}
        mesEnFoco={mesEnFoco}
      />
    </>
  );
}

export default Agenda;
