
import styles from './services.module.css'
import Services from '../../components/services';

function ServicesPage() {
  return (
    <div className={styles.CONTAINER}>
      <div className={styles.BANNER}> SERVICIOS</div>
      <Services />
    </div>
  )
}

export default ServicesPage;