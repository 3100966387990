import styles from "./us.module.css";
import { us, vision, mission, env_policy, compromise } from "../../copys";
import { IoLeafOutline, IoBookOutline } from "react-icons/io5";
import { GoGoal } from "react-icons/go";
import { PiBinocularsDuotone } from "react-icons/pi";
import { LuHeartHandshake } from "react-icons/lu";

function Us() {
  return (
    <div className={styles.CONTAINER}>
      <div className={styles.BANNER}> PORQUE NOSOTROS?</div>
      <div className={styles.PANEL}>
        <div className={styles.COLUMN}>
          <section>
            <div className={styles.HEAD_CARD}>
              <IoBookOutline />
              <h1> HISTORIA </h1>
            </div>
            <hr />
          </section>
          <span>{us}</span>
        </div>
        <div className={styles.COLUMN}>
          <section>
            <div className={styles.HEAD_CARD}>
              <PiBinocularsDuotone />
              <h1> VISION </h1>
            </div>
            <hr />
          </section>
          <span>{vision}</span>
        </div>
        <div className={styles.COLUMN}>
          <section>
            <div className={styles.HEAD_CARD}>
              <GoGoal />
              <h1> MISION </h1>
            </div>
            <hr />
          </section>
          <span>{mission}</span>
        </div>
      </div>
      <div className={styles.ENV_POLICY}>
        <div className={styles.COLUMN}>
          <section>
            <div className={styles.HEAD_CARD}>
              <IoLeafOutline />
              <h1> POLITICA AMBIENTAL </h1>
            </div>
            <hr />
          </section>
          <span>{env_policy}</span>
        </div>
        <div className={styles.COLUMN1}>
          <section>
            <div className={styles.HEAD_CARD}>
              <LuHeartHandshake />
              <h1> COMPROMISO </h1>
            </div>
            <hr />
          </section>
          <span>{compromise}</span>
        </div>
      </div>
    </div>
  );
}

export default Us;
