import React, { useEffect, useState } from "react";
import styles from "./calendar.module.css";
import { Dia } from "../../../interfaces";

function CalendarInLine(props: {
  fechas: Dia[];
  mesEnFoco: string;
  diaEnFoco: number;
  seleccionarDia: Function;
}) {
  const { fechas, mesEnFoco, diaEnFoco, seleccionarDia } = props;

  // Obtener una lista única de meses en los próximos 30 días
  const mesesUnicos = Array.from(new Set(fechas.map((fecha) => fecha.mes)));

  return (
    <div className={styles.container}>
      {/* Renderización dinámica de los meses en foco */}
      <div className={styles.mesesContainer}>
        {mesesUnicos.map((mes, index) => (
          <span
            key={index}
            style={{
              fontWeight: mesEnFoco === mes ? "bold" : "normal",
              color: mesEnFoco === mes ? "black" : "gray",
              fontSize: "16px",
              marginRight: "20px",
              borderBottom: mesEnFoco === mes ? "2px solid black" : "none",
              paddingBottom: "5px",
              cursor: "pointer",
            }}
          >
            {mes.charAt(0).toUpperCase() + mes.slice(1)}
          </span>
        ))}
      </div>

      {/* Renderización de los días */}
      <div className={styles.diascontainer}>
        {fechas.map((fecha, index) => {
          const esDiaPasado =
            fecha.dia == "dom" ||
            (fecha.mesNum == new Date().getMonth() + 1 &&
              fecha.numero < new Date().getDate());
          return (
            <div
              key={index}
              onClick={() => !esDiaPasado && seleccionarDia(fecha)}
              className={styles.diaitem}
              style={{
                backgroundColor:
                  mesEnFoco === fecha.mes && diaEnFoco === fecha.numero
                    ? "#000"
                    : "transparent",
                color: esDiaPasado
                  ? "gray"
                  : mesEnFoco === fecha.mes && diaEnFoco === fecha.numero
                  ? "#fff"
                  : "#000",
                cursor: esDiaPasado ? "not-allowed" : "pointer",
              }}
            >
              <div className={styles.diaNombre}>{fecha.dia.toUpperCase()}</div>
              <div className={styles.diaNumero}>{fecha.numero}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default CalendarInLine;
