import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

const images = [
  {
    original: 'https://plus.unsplash.com/premium_photo-1661501072174-26da76dbaae3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
    thumbnail: 'https://picsum.photos/id/1018/250/150/',
    // originalHeight: 400
  },
  {
    original: 'https://images.unsplash.com/photo-1575844611398-2a68400b437c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=872&q=80',
    thumbnail: 'https://picsum.photos/id/1015/250/150/',
    // originalHeight: 400
  },
  {
    original: 'https://images.unsplash.com/photo-1632685062337-095b722134ca?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
    thumbnail: 'https://picsum.photos/id/1019/250/150/',
    // originalHeight: 400
  },
];

function Carousel() {

  return (
    <ImageGallery 
      items={images}
      infinite={true} 
      showThumbnails={false} 
      autoPlay={true} 
      showFullscreenButton={false}
      showPlayButton={false}
      showNav={false}
      showBullets={false}
      slideInterval={4000}
    />
  )
}

export default Carousel;