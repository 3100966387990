import styles from "./client.module.css";

const colabs = [
  "NEUCO",
  "VALFER SRL",
  "EPEN",
  "GSP (GRUPO STELL PATAGONIA)",
  "AKINA SA",
  "SAUCO SRL",
  "CODESIN SA",
  "25 de Mayo SA",
  "PASON DGS",
  "SIMETRA SRL",
  "CEPEC SRL",
];

function Clients() {
  const array = colabs.slice(0, 5);
  const newColabs = [...colabs, ...array];
  return (
    <div className={styles.marquee}>
      <ul className={styles.marquee_content}>
        {newColabs.map((item, index) => (
          <li>{item}</li>
        ))}
      </ul>
    </div>
  );
}

export default Clients;
